import { PUBLIC_PATHS } from 'constants/paths'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import './style.less'

const descTransitionAnim = 'all 300ms ease 0s'

const GalleryItem = ({ dataid, dataorder, imgUrl, projectname, contents }) => {
  const [descLeft, setDescLeft] = useState('100%')
  const [descTop, setDescTop] = useState('0')
  const [descTrans, setDescTrans] = useState(descTransitionAnim)
  

  const divEl = useRef(null)
  function mouseEnter(e) {
    let edge = _closestEdge(e, divEl.current)
    switch (edge) {
      case 'left':
        setDescLeft('-100%')
        setDescTop('0')
        break
      case 'right':
        setDescLeft('100%')
        setDescTop('0')
        break
      case 'top':
        setDescLeft('0')
        setDescTop('-100%')
        break
      case 'bottom':
        setDescLeft('0')
        setDescTop('100%')
        break
      default:
        break
    }
    setDescTrans('none')
    setTimeout(() => {
      setDescTop('0')
      setDescLeft('0')
      setDescTrans(descTransitionAnim)
    }, 10)
  }

  function mouseLeave(e) {
    let edge = _closestEdge(e, divEl.current)
    setDescLeft('0')
    setDescTop('0')
    setDescTrans('none')

    switch (edge) {
    case 'left':
      setTimeout(() => {
        setDescLeft('-100%')
        setDescTrans(descTransitionAnim)
      }, 10)
      break
    case 'right':
      setTimeout(() => {
        setDescLeft('100%')
        setDescTrans(descTransitionAnim)
      }, 10)
      break
    case 'top':
      setTimeout(() => {
        setDescTop('-100%')
        setDescTrans(descTransitionAnim)
      }, 10)
      break
    case 'bottom':
      setTimeout(() => {
        setDescTop('100%')
        setDescTrans(descTransitionAnim)
      }, 10)
      break
    default:
      break
    }
  }

  function _closestEdge(e, divEl) {
    let elemBounding = divEl.getBoundingClientRect()
    let w = elemBounding.width
    let h = elemBounding.height
    let x = e.screenX - elemBounding.left
    let y = e.screenY - elemBounding.top

    let xShift, yShift, xEdge, yEdge;
    if (x / w > .5) {
        xShift = w - x;
        xEdge = 'right';
    } else {
        xShift = x;
        xEdge = 'left';
    }
    if (y / h > .5) {
        yShift = h - y;
        yEdge = 'bottom';
    } else {
        yShift = y;
        yEdge = 'top';
    }
    return (xShift < yShift) ? xEdge : yEdge;
  }

  return (
    <div
      className="project"
      data-id={dataid}
      data-order={dataorder}
      style={{ opacity: 1 }}
    >
      <Link to={`${PUBLIC_PATHS.PORTFOLIO}/${projectname}`}>
        <div
          className="image"
          ref={divEl}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        >
          <img alt={projectname} src={imgUrl} />
          <div className={`project-description`} style={{ left: descLeft, top: descTop, transition: descTrans}}>
            <div className="content">
              <div className="wrapper">
                <div className="project-name">{projectname}</div>
                <ul className="tag-list">
                  {contents.map((content, index) => {
                    return <li key={index}>{content}</li>
                  })}
                </ul>
                <div className="go-to">
                  <div>Go to case</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default GalleryItem
