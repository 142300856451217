const slidesdefault = [
  {
    webp: '',
    png: require('../../assets/images/about/slide-01.png').default,
    alt: 'image-block-1'
  },
  {
    webp: '',
    png: require('../../assets/images/about/slide-02.png').default,
    alt: 'image-block-2'
  },
  {
    webp: '',
    png: require('../../assets/images/about/slide-03.png').default,
    alt: 'image-block-3'
  },
  {
    webp: '',
    png: require('../../assets/images/about/slide-04.png').default,
    alt: 'image-block-4'
  }
]

const HeroBlock = ({ slides, content, summary, button }) => {
  slides = slides || slidesdefault
  return (
    <div className="hero-block">
      <div className="image default-images">
        {slides.map((slide) => (
          <picture key={slide.alt}>
            <source srcSet={slide.webp} type="image/webp" />
            <img alt={slide.alt} src={slide.png} />
          </picture>
        ))}
      </div>
      <div className="content">
        <h1>{content}</h1>
        <div className="summary">{summary}</div>
        {button !== undefined && (
          <a className="button-outline-red" href="/company/contacts/#address">
            {button}
          </a>
        )}
      </div>
    </div>
  )
}

export default HeroBlock
