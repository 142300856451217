import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

const DataItem = ({ item, index }) => {
  const { title, subTitle, content } = item
  const [isItemShown, setIsItemShown] = useState(false)

  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) {
          setIsItemShown(true)
        }
      }}
    >
      <span
        className={`item${
          index === 0
            ? ''
            : index === 1
            ? ' addtional padding'
            : index === 2
            ? ' grey-bg additional_padding'
            : ' grey-bg'
        } ${isItemShown ? 'show' : ''}`}
      >
        <span className="head">
          <span className="bold">{title}</span>
          <span className="red">{subTitle}</span>
        </span>
        <span>{content}</span>
      </span>
    </VisibilitySensor>
  )
}

export default DataItem
