import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

// Import Swiper styles
// import 'swiper/swiper.less';

SwiperCore.use([Pagination])

const testimonials = [
  {
    author: {
      name: 'Sean Grundy',
      title: 'Co-founder, CEO',
      company: 'Bevi',
      link: 'https://www.linkedin.com/in/seangrundy',
      photo: require('assets/img/testimonials/sean.webp').default,
      photo_small: require('assets/img/testimonials/sean.png').default
    },
    text: "As an IoT company, we've been impressed at how effectively iTechArt has been able to help us from afar. In addition to having an extremely talented technical team, they are clear and comprehensive when it comes to project planning, budgeting, collecting user feedback, and revising their work."
  },
  {
    author: {
      name: 'Sean Grundy',
      title: 'Co-founder, CEO',
      company: 'Bevi',
      link: 'https://www.linkedin.com/in/seangrundy',
      photo: require('assets/img/testimonials/sean.webp').default,
      photo_small: require('assets/img/testimonials/sean.png').default
    },
    text: "As an IoT company, we've been impressed at how effectively iTechArt has been able to help us from afar. In addition to having an extremely talented technical team, they are clear and comprehensive when it comes to project planning, budgeting, collecting user feedback, and revising their work."
  }
]

const Testimonials = () => {
  return (
    <div className="testimonials__wrapper">
      <Swiper
        className="block-content"
        //spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {testimonials.map(({ author, text }, idx) => (
          <SwiperSlide className="testimonial-item">
            <div className="testimonial-author">
              <div className="testimonial-author__image">
                <a href={author.link} target="_blank">
                  <picture className="">
                    <source srcSet={author.photo} type="image/webp" />
                    <img alt={author.name} src={author.photo_small} />
                  </picture>
                </a>
              </div>
              <div className="testimonial-author__info">
                <span>{author.name}</span>
                <span>,</span>
                <span className="new-line">{author.title}</span>
                <span className="author-company">at {author.company}</span>
              </div>
            </div>
            <div className="testimonial-text">
              <p>{text}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Testimonials
