export const gallery_data = [
  {
    dataid: '694',
    dataorder: '1',
    imgUrl: require('assets/images/portfolio/classpass_case_hr.png').default,
    detail: {
      imgTopUrl: require('assets/images/portfolio/portfolio-detail/bevi-02.original.format-png.png')
        .default,
      headerBlock: {
        description:
          'Bevi cuts costs and saves energy from the bottled beverage supply chain with next-generation water dispensers that purify, flavor and carbonate tap water at the point of use.',
        type: "YEARS'",
        period: '6',
        subTitleTop: 'WEB SOLUTION',
        subTitleBottom: 'MOBILE APPS'
      },
      blocks: [
        {
          title: 'Challenge',
          description: {
            text: [
              'Bevi is a Techstars honor grad focused on meeting the demands of today’s healthy workplace. They introduced eco-friendly water coolers that encourage people to stay hydrated during the workday.',
              'As Bevi received overwhelmingly positive feedback for providing a highly sustainable product, they were looking for a software development partner that would be able to keep up with their fast pace and rapid growth.',
              'iTechArt was ready to build a solution that would synchronize with the hardware module to manage Bevi’s product.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-03.original.format-png.png')
              .default,
            isRight: false
          }
        },
        {
          title: 'Solution',
          description: {
            text: [
              'Backed by solid mobile development expertise, our team delivered an Android-powered app from scratch that facilitated control over Bevi’s machine hardware.',
              'The resulting solution enabled end-users to choose from a variety of flavors and enhanced their overall experience.',
              'The delivered functionality and enhanced system productivity ensured a smooth experience for end users that ultimately resulted in a more competitive market stance.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-04.original.format-png.png')
              .default,
            isRight: true
          }
        }
      ],
      stacks: [
        {
          title: 'Mobile',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        }
      ]
    },
    projectname: 'ClassPass',
    tags: ['Wellness', 'Fitness']
  },
  {
    dataid: '757',
    dataorder: '2',
    imgUrl: require('assets/images/portfolio/freshly_case.png').default,
    detail: {
      imgTopUrl: require('assets/images/portfolio/portfolio-detail/bevi-02.original.format-png.png')
        .default,
      headerBlock: {
        description:
          'Bevi cuts costs and saves energy from the bottled beverage supply chain with next-generation water dispensers that purify, flavor and carbonate tap water at the point of use.',
        type: "YEARS'",
        period: '6',
        subTitleTop: 'WEB SOLUTION',
        subTitleBottom: 'MOBILE APPS'
      },
      blocks: [
        {
          title: 'Challenge',
          description: {
            text: [
              'Bevi is a Techstars honor grad focused on meeting the demands of today’s healthy workplace. They introduced eco-friendly water coolers that encourage people to stay hydrated during the workday.',
              'As Bevi received overwhelmingly positive feedback for providing a highly sustainable product, they were looking for a software development partner that would be able to keep up with their fast pace and rapid growth.',
              'iTechArt was ready to build a solution that would synchronize with the hardware module to manage Bevi’s product.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-03.original.format-png.png')
              .default,
            isRight: false
          }
        },
        {
          title: 'Solution',
          description: {
            text: [
              'Backed by solid mobile development expertise, our team delivered an Android-powered app from scratch that facilitated control over Bevi’s machine hardware.',
              'The resulting solution enabled end-users to choose from a variety of flavors and enhanced their overall experience.',
              'The delivered functionality and enhanced system productivity ensured a smooth experience for end users that ultimately resulted in a more competitive market stance.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-04.original.format-png.png')
              .default,
            isRight: true
          }
        }
      ],
      stacks: [
        {
          title: 'Mobile',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        }
      ]
    },
    projectname: 'Freshly',
    tags: ['FoodTech', 'Ecomerce']
  },
  {
    dataid: '698',
    dataorder: '3',
    imgUrl: require('assets/images/portfolio/forex_case.png').default,
    detail: {
      imgTopUrl: require('assets/images/portfolio/portfolio-detail/bevi-02.original.format-png.png')
        .default,
      headerBlock: {
        description:
          'Bevi cuts costs and saves energy from the bottled beverage supply chain with next-generation water dispensers that purify, flavor and carbonate tap water at the point of use.',
        type: "YEARS'",
        period: '6',
        subTitleTop: 'WEB SOLUTION',
        subTitleBottom: 'MOBILE APPS'
      },
      blocks: [
        {
          title: 'Challenge',
          description: {
            text: [
              'Bevi is a Techstars honor grad focused on meeting the demands of today’s healthy workplace. They introduced eco-friendly water coolers that encourage people to stay hydrated during the workday.',
              'As Bevi received overwhelmingly positive feedback for providing a highly sustainable product, they were looking for a software development partner that would be able to keep up with their fast pace and rapid growth.',
              'iTechArt was ready to build a solution that would synchronize with the hardware module to manage Bevi’s product.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-03.original.format-png.png')
              .default,
            isRight: false
          }
        },
        {
          title: 'Solution',
          description: {
            text: [
              'Backed by solid mobile development expertise, our team delivered an Android-powered app from scratch that facilitated control over Bevi’s machine hardware.',
              'The resulting solution enabled end-users to choose from a variety of flavors and enhanced their overall experience.',
              'The delivered functionality and enhanced system productivity ensured a smooth experience for end users that ultimately resulted in a more competitive market stance.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-04.original.format-png.png')
              .default,
            isRight: true
          }
        }
      ],
      stacks: [
        {
          title: 'Mobile',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        }
      ]
    },
    projectname: 'Forex.com',
    tags: ['Forex Trading', 'FinTech']
  },
  {
    dataid: '953',
    dataorder: '4',
    imgUrl: require('assets/images/portfolio/bevi_case.png').default,
    detail: {
      imgTopUrl: require('assets/images/portfolio/portfolio-detail/bevi-02.original.format-png.png')
        .default,
      headerBlock: {
        description:
          'Bevi cuts costs and saves energy from the bottled beverage supply chain with next-generation water dispensers that purify, flavor and carbonate tap water at the point of use.',
        type: "YEARS'",
        period: '6',
        subTitleTop: 'WEB SOLUTION',
        subTitleBottom: 'MOBILE APPS'
      },
      blocks: [
        {
          title: 'Challenge',
          description: {
            text: [
              'Bevi is a Techstars honor grad focused on meeting the demands of today’s healthy workplace. They introduced eco-friendly water coolers that encourage people to stay hydrated during the workday.',
              'As Bevi received overwhelmingly positive feedback for providing a highly sustainable product, they were looking for a software development partner that would be able to keep up with their fast pace and rapid growth.',
              'iTechArt was ready to build a solution that would synchronize with the hardware module to manage Bevi’s product.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-03.original.format-png.png')
              .default,
            isRight: false
          }
        },
        {
          title: 'Solution',
          description: {
            text: [
              'Backed by solid mobile development expertise, our team delivered an Android-powered app from scratch that facilitated control over Bevi’s machine hardware.',
              'The resulting solution enabled end-users to choose from a variety of flavors and enhanced their overall experience.',
              'The delivered functionality and enhanced system productivity ensured a smooth experience for end users that ultimately resulted in a more competitive market stance.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-04.original.format-png.png')
              .default,
            isRight: true
          }
        }
      ],
      stacks: [
        {
          title: 'Mobile',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        }
      ]
    },
    projectname: 'Bevi',
    tags: ['Food and Beverage']
  },
  {
    dataid: '749',
    dataorder: '5',
    imgUrl: require('assets/images/portfolio/blackboard_case.png').default,
    detail: {
      imgTopUrl: require('assets/images/portfolio/portfolio-detail/bevi-02.original.format-png.png')
        .default,
      headerBlock: {
        description:
          'Bevi cuts costs and saves energy from the bottled beverage supply chain with next-generation water dispensers that purify, flavor and carbonate tap water at the point of use.',
        type: "YEARS'",
        period: '6',
        subTitleTop: 'WEB SOLUTION',
        subTitleBottom: 'MOBILE APPS'
      },
      blocks: [
        {
          title: 'Challenge',
          description: {
            text: [
              'Bevi is a Techstars honor grad focused on meeting the demands of today’s healthy workplace. They introduced eco-friendly water coolers that encourage people to stay hydrated during the workday.',
              'As Bevi received overwhelmingly positive feedback for providing a highly sustainable product, they were looking for a software development partner that would be able to keep up with their fast pace and rapid growth.',
              'iTechArt was ready to build a solution that would synchronize with the hardware module to manage Bevi’s product.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-03.original.format-png.png')
              .default,
            isRight: false
          }
        },
        {
          title: 'Solution',
          description: {
            text: [
              'Backed by solid mobile development expertise, our team delivered an Android-powered app from scratch that facilitated control over Bevi’s machine hardware.',
              'The resulting solution enabled end-users to choose from a variety of flavors and enhanced their overall experience.',
              'The delivered functionality and enhanced system productivity ensured a smooth experience for end users that ultimately resulted in a more competitive market stance.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-04.original.format-png.png')
              .default,
            isRight: true
          }
        }
      ],
      stacks: [
        {
          title: 'Mobile',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        }
      ]
    },
    projectname: 'Blackboard',
    tags: ['EdTech', 'Elearning']
  },
  {
    dataid: '991',
    dataorder: '6',
    imgUrl: require('assets/images/portfolio/rallyrd_case_hr.png').default,
    detail: {
      imgTopUrl: require('assets/images/portfolio/portfolio-detail/bevi-02.original.format-png.png')
        .default,
      headerBlock: {
        description:
          'Bevi cuts costs and saves energy from the bottled beverage supply chain with next-generation water dispensers that purify, flavor and carbonate tap water at the point of use.',
        type: "YEARS'",
        period: '6',
        subTitleTop: 'WEB SOLUTION',
        subTitleBottom: 'MOBILE APPS'
      },
      blocks: [
        {
          title: 'Challenge',
          description: {
            text: [
              'Bevi is a Techstars honor grad focused on meeting the demands of today’s healthy workplace. They introduced eco-friendly water coolers that encourage people to stay hydrated during the workday.',
              'As Bevi received overwhelmingly positive feedback for providing a highly sustainable product, they were looking for a software development partner that would be able to keep up with their fast pace and rapid growth.',
              'iTechArt was ready to build a solution that would synchronize with the hardware module to manage Bevi’s product.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-03.original.format-png.png')
              .default,
            isRight: false
          }
        },
        {
          title: 'Solution',
          description: {
            text: [
              'Backed by solid mobile development expertise, our team delivered an Android-powered app from scratch that facilitated control over Bevi’s machine hardware.',
              'The resulting solution enabled end-users to choose from a variety of flavors and enhanced their overall experience.',
              'The delivered functionality and enhanced system productivity ensured a smooth experience for end users that ultimately resulted in a more competitive market stance.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-04.original.format-png.png')
              .default,
            isRight: true
          }
        }
      ],
      stacks: [
        {
          title: 'Mobile',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        }
      ]
    },
    projectname: 'Rally Rd.',
    tags: ['Automative', 'FinTech']
  },
  {
    dataid: '998',
    dataorder: '7',
    imgUrl: require('assets/images/portfolio/rallyrd_case_hr.png').default,
    detail: {
      imgTopUrl: require('assets/images/portfolio/portfolio-detail/bevi-02.original.format-png.png')
        .default,
      headerBlock: {
        description:
          'Bevi cuts costs and saves energy from the bottled beverage supply chain with next-generation water dispensers that purify, flavor and carbonate tap water at the point of use.',
        type: "YEARS'",
        period: '6',
        subTitleTop: 'WEB SOLUTION',
        subTitleBottom: 'MOBILE APPS'
      },
      blocks: [
        {
          title: 'Challenge',
          description: {
            text: [
              'Bevi is a Techstars honor grad focused on meeting the demands of today’s healthy workplace. They introduced eco-friendly water coolers that encourage people to stay hydrated during the workday.',
              'As Bevi received overwhelmingly positive feedback for providing a highly sustainable product, they were looking for a software development partner that would be able to keep up with their fast pace and rapid growth.',
              'iTechArt was ready to build a solution that would synchronize with the hardware module to manage Bevi’s product.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-03.original.format-png.png')
              .default,
            isRight: false
          }
        },
        {
          title: 'Solution',
          description: {
            text: [
              'Backed by solid mobile development expertise, our team delivered an Android-powered app from scratch that facilitated control over Bevi’s machine hardware.',
              'The resulting solution enabled end-users to choose from a variety of flavors and enhanced their overall experience.',
              'The delivered functionality and enhanced system productivity ensured a smooth experience for end users that ultimately resulted in a more competitive market stance.'
            ]
          },
          imgDetail: {
            imgUrl: require('assets/images/portfolio/portfolio-detail/bevi-04.original.format-png.png')
              .default,
            isRight: true
          }
        }
      ],
      stacks: [
        {
          title: 'Mobile',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        },
        {
          title: 'Frontend',
          items: ['Java', 'Android SDK', 'Fragments', 'Jackson/Gson']
        }
      ]
    },
    projectname: 'Rally Rd. New',
    tags: ['Automative', 'FinTech']
  }
]
export const tags = [
  {
    name: 'Angular',
    value: 'angular'
  },
  {
    name: 'React',
    value: 'react'
  },
  {
    name: 'Ruby on Rails',
    value: 'ror'
  },
  {
    name: 'Python',
    value: 'python'
  },
  {
    name: 'PHP',
    value: 'php'
  },
  {
    name: 'Node',
    datatag: 'node'
  },
  {
    name: '.NET',
    value: 'dotnet'
  },
  {
    name: 'Java',
    value: 'java'
  },
  {
    name: 'SalesForce',
    value: 'sf'
  },
  {
    name: 'Mobile',
    value: 'mobile'
  },
  {
    name: 'QA and Testing',
    value: 'qa'
  },
  {
    name: 'React Native',
    value: 'react native'
  }
]
