import React from 'react'

const ContainerStack = ({ stacks }) => {
  return (
    <span className="container-1200 stack">
      <span className="data">
        <h2 className="line">Stack</h2>
        <span className="flexbox list-items percent-30">
          {stacks &&
            stacks.length > 0 &&
            stacks.map((stack) => (
              <span className="item">
                <span className="head">{stack.title}</span>
                <ul>
                  {stack.items.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </span>
            ))}
        </span>
      </span>
    </span>
  )
}

export default ContainerStack
