import React, { useContext } from 'react'
import { TilesWithLinksContext } from '..'

const TileItem = ({ item, index }) => {
  const { title, content, icon, link } = item
  const { activeIndex, setActiveIndex } = useContext(TilesWithLinksContext)
  const isActive = activeIndex === index

  return (
    <div
      className={`tile-item ${isActive ? 'active' : ''}`}
      onMouseEnter={() => setActiveIndex(index)}
    >
      <a href={link}>
        <div className="tile-icon">
          <img alt={title} src={icon} />
        </div>
        <div className="tile-title">{title}</div>
        <div className="tile-text">{content}</div>
        <div className="arrow"></div>
      </a>
    </div>
  )
}

export default TileItem
