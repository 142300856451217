import React, { useState, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Autoplay } from 'swiper'

SwiperCore.use([EffectFade, Autoplay])

const showData = {
  2002: {
    descs: [
      'The founding team starts toying with own startup projects',
      'Team up with DAQ Electronics — our very first client, staying with us till today'
    ],
    imgs: [{ label: 'New York, NY', file: 'about_us_new_york.original' }]
  },
  2006: {
    descs: [
      'iTechArt grand opening in the United States',
      'Reach a total of 15 active clients',
      'First clients in Europe'
    ],
    imgs: [{ label: 'Iselin, NJ', file: 'about_us_Iselin_NJ.original' }]
  },
  2008: {
    descs: [
      'Start working with a performance marketing company Performics, later acquired by Google',
      'Upload our first Android app on Play Market'
    ],
    imgs: [{ label: '50 devs', file: 'about_us_dev_pool_copy.original' }]
  },
  2009: {
    descs: [
      'Our first 5-star rated iOS application launches in the App Store',
      'First clients in Australia',
      'Kick-off of the software development hub in Minsk, Belarus'
    ],
    imgs: [{ label: 'Minsk, Belarus', file: 'about_us_Minsk.original' }]
  },
  2010: {
    descs: [
      'Gain Capital becomes the first client going public',
      'iTechArt-developed apps hit a whopping 10 million daily users in total',
      'Clients on all continents'
    ],
    imgs: [{ label: '150 devs', file: 'about_us_dev_pool_copy.original' }]
  },
  2012: {
    descs: [
      'The first-ever exit of the client made in NYC — Pixable was acquired by SingTel',
      'Clients in every thriving U.S. startup hub — Silicon Valley, Boston, New York, and Austin',
      'iTechArt’s roster grows with the 100th active client',
      'Opening of Students Lab — our own dev school'
    ],
    imgs: [
      { label: '20 grads a year', file: 'about_us_student_lab_copy.original' },
      { label: '300 devs', file: 'about_us_dev_pool_copy.original' }
    ]
  },
  2015: {
    descs: [
      'Unicorns become real — our first nine-figure startup client',
      '10 IPOed clients',
      'iTechArt dives into IoT domain',
      'Start supporting CTO Connection — a go-to event for top engineering leaders'
    ],
    imgs: [
      { label: 'First unicorn client', file: 'about_us_unicorn_copy.original' }
    ]
  },
  2016: {
    descs: [
      '10 dev and 5 QA departments operate within the company, covering all in-demand technologies',
      'First startup clients working on AI-backed applications',
      'Hold the first internal conference iTechForum, hosting more than 1,000 guests'
    ],
    imgs: [{ label: 'Marietta, GA', file: 'about_us_GA_Marietta.original' }]
  },
  2017: {
    descs: [
      'Dev Millenium — we hire our 1,000th engineer',
      '200 active clients',
      'First blockchain project',
      'Taking over the Clutch.co and GoodFirms charts as a top engineering company'
    ],
    imgs: [{ label: '1000 devs', file: 'about_us_dev_pool_copy.original' }]
  },
  2018: {
    descs: [
      '15 IPOed clients',
      'Rally Rd. raised $7M Series A with an MVP built by iTechArt',
      'Inc. 5000 debut'
    ],
    imgs: [
      { label: '1400 devs', file: 'about_us_dev_pool_copy.original' },
      { label: '10 unicorn clients', file: 'about_us_unicorn_copy.original' }
    ]
  },
  2019: {
    descs: [
      'For the second year in a row, iTechArt is recognized as one of the fastest-growing companies according to Inc. 5000',
      'iTechArt becomes a bronze winner of Stevie® Awards',
      'Named on Deloitte’s Technology Fast 500™ list of сompanies',
      'Collaborate with Salesforce as a Silver Consulting Partner!'
    ],
    imgs: [
      { label: 'London, UK', file: 'about_us_london.original' },
      { label: '1500 devs', file: 'about_us_dev_pool_copy.original' }
    ]
  }
}

const History = ({ _ }) => {
  const [lineRedWidth, setLineRedWidth] = useState(10000)
  const [lineScrollX, setLineScrollX] = useState(0)

  const swiperRef = useRef()
  const lineGrayRef = useRef()

  const yearsarray = Object.keys(showData)

  const bulletItemsRef = useRef([])
  bulletItemsRef.current = yearsarray.map(
    (_, i) => bulletItemsRef.current[i] ?? React.createRef()
  )

  const [selectedYearIdx, setSelectedYearIdx] = useState(yearsarray.length - 1)

  return (
    <>
      <div className="container-1200">
        <h2 className="history">
          Our development: <br />
          HopanaTech through the years
        </h2>
      </div>

      <div className="our_history container-1200">
        <Swiper
          className="swiper-container-fade"
          speed={400}
          spaceBetween={100}
          slidesPerGroupSkip={20}
          allowTouchMove={false}
          followFinger={false}
          preventClicksPropagation={false}
          onAfterInit={(swiper) => {}}
          effect="fade"
          fadeEffect={{
            crossFade: true
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper
            setTimeout(() => {
              swiper.slideTo(yearsarray.length - 1)
            })
          }}
          onSlideChange={(slide) => {
            setSelectedYearIdx(slide.activeIndex)
            const activeRed = bulletItemsRef.current[slide.activeIndex].current
            const activeRedRadius = activeRed.getBoundingClientRect().width / 2
            const activeRedLeft = activeRed.offsetLeft
            setLineRedWidth(activeRedLeft)

            const swiperRect = swiperRef.current.el.getBoundingClientRect()
            const lineFullWidth =
              lineGrayRef.current.getBoundingClientRect().width

            if (lineFullWidth >= swiperRect.width) {
              const margin = 15 //margin 15px

              setLineScrollX(
                Math.max(
                  Math.min(
                    swiperRect.width / 2 - activeRedLeft - activeRedRadius,
                    margin
                  ),
                  -(lineFullWidth + margin)
                )
              )
            }
          }}
        >
          {yearsarray.map((year, idx) => (
            <SwiperSlide key={year}>
              <div>
                <div>
                  <ul>
                    {showData[year].descs.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div>
                {showData[year].imgs.map((image, index) => (
                  <div key={index}>
                    <div>
                      <img
                        alt={image.label}
                        src={
                          require(`assets/images/about/${image.file}.png`)
                            .default
                        }
                      />
                    </div>
                    <div>{image.label}</div>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
          <div
            className="swiper-pagination swiper-pagination-bullets"
            style={{ transform: `translateX(${lineScrollX}px)` }}
          >
            {yearsarray.map((year, idx) => (
              <div
                key={year}
                className={
                  (idx <= selectedYearIdx ? 'red ' : '') +
                  (idx === selectedYearIdx ? 'active ' : '') +
                  'bullet'
                }
                onClick={(e) => {
                  e.preventDefault()
                  swiperRef.current.slideTo(idx)
                }}
                ref={bulletItemsRef.current[idx]}
                style={{
                  marginRight: `${
                    idx < yearsarray.length - 1 &&
                    yearsarray[idx + 1] - year > 1
                      ? (yearsarray[idx + 1] - year) * 2
                      : 0
                  }%`
                }}
              >
                <div>{year}</div>
                <div
                  className={
                    'swiper-pagination-bullet' +
                    (idx === selectedYearIdx
                      ? ' swiper-pagination-bullet-active'
                      : '')
                  }
                ></div>
              </div>
            ))}
          </div>
        </Swiper>
        <div
          className="line"
          style={{
            width: `${lineRedWidth}px`,
            transform: `translateX(${lineScrollX}px)`
          }}
        ></div>
        <div className="line_gray" ref={lineGrayRef}></div>
      </div>
    </>
  )
}
export default History
