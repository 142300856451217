import { MapContainer, Form } from 'components/contacts'

const ContactUs = ({ _ }) => {
  return (
    <div className="contacts contacts-v1">
      <MapContainer />
      <div className="content vertical-slider-item" data-id="#address_">
        <div className="container-800 flexbox">
          <Form />
        </div>
      </div>
    </div>
  )
}
export default ContactUs
