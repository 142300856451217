/**
 * @fileOverview Layer
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

function Layer(props) {
  const { children, className, ...others } = props
  const layerClass = classNames('charts-layer', className)

  return (
    <g className={layerClass} {...others}>
      {children}
    </g>
  )
}

Layer.propTypes = propTypes

export default Layer
