import React, { useEffect, useState, useRef, createRef } from 'react'
import TypeCaret from './typeit-caret'

const items = [
  {
    mediaURL: {
      mp4: require('assets/videos/lamp.mp4').default,
      webm: require('assets/videos/lamp.mp4').default
    },
    title: 'Discovery',
    content:
      'Once we learn more about your needs, we start building a team able to complete your mission on time and in line with users’ and investors’ expectations.'
  },
  {
    mediaURL: {
      mp4: require('assets/videos/people.mp4').default,
      webm: require('assets/videos/people.webm').default
    },
    title: 'Setup',
    content:
      'We get back to you with the first thoroughly pre-screened CVs. You interview future team members to find those that are a perfect fit for your company.'
  },
  {
    mediaURL: {
      mp4: require('assets/videos/finger.mp4').default,
      webm: require('assets/videos/finger.webm').default
    },
    title: 'Kickoff',
    content:
      'Your brand-new software development powerhouses work back-to-back with you to put your solution in motion.'
  },
  {
    mediaURL: {
      mp4: require('assets/videos/rocket.mp4').default,
      webm: require('assets/videos/rocket.webm').default
    },
    title: 'Ramp up',
    content:
      'Fresh off the release, we scale your team to match your product’s increasing success.'
  }
]

const content = {
  theme: 'black',
  title: {
    text: 'Meet your',
    type: 'caret',
    items: ['team', 'user', 'support']
  },
  subtitle: '',
  media: 'video',
  items: items
}

const RedLineChooser = () => {
  const isVideo = content.media === 'video'
  const [currentItem, setCurrentItem] = useState(0)
  const selVideo = useRef(null)
  const videos = []

  if (isVideo) {
    for (let i = 0; i < content.items.length; i++) {
      videos.push(createRef())
    }
  }

  useEffect(() => {
    if (isVideo) {
      videos[0].current.play()
      selVideo.current.play()
    }
  }, [])

  const onItemClicked = (idx) => {
    setCurrentItem((prev) => {
      if (isVideo) {
        videos[prev].current.pause()
        videos[prev].current.currentTime = 0
        videos[idx].current.play()
      }
      return idx
    })
  }

  return (
    <div className={`${content.theme}`}>
      <span className="container-1200">
        <span className="data">
          {content.title.type === 'caret' && (
            <h3 className="center-text title">
              {content.title.text} <TypeCaret strings={content.title.items} />
              <span className="caret">|</span>
            </h3>
          )}
          {content.title.type === 'text' && (
            <h3 className="center-text title">{content.text}</h3>
          )}
          <span>{content.subtitle}</span>
          {isVideo ? (
            <video
              loop
              muted={true}
              className="mobile_video"
              autoPlay
              playsinline
              webkit-playsinline
              ref={selVideo}
              key={items[currentItem].mediaURL}
            >
              <source src={items[currentItem].mediaURL.mp4} type="video/mp4" />
              <source
                src={items[currentItem].mediaURL.webm}
                type="video/webm"
              />
            </video>
          ) : (
            <img
              className="mobile_image"
              src={items[currentItem].mediaURL}
            ></img>
          )}
          <span className="red-underline-chooser">
            <span className="items">
              {items.map(({ title, content, mediaURL }, idx) => (
                <span
                  class={idx == currentItem ? 'in_action' : ''}
                  onClick={() => onItemClicked(idx)}
                >
                  <span className="head">{title}</span>
                  <span className="text">{content}</span>
                  {isVideo ? (
                    <video
                      loop
                      muted={true}
                      playsinline
                      webkit-playsinline
                      ref={videos[idx]}
                    >
                      <source src={mediaURL.mp4} type="video/mp4" />
                      <source src={mediaURL.webm} type="video/webm" />
                    </video>
                  ) : (
                    <img src={mediaURL}></img>
                  )}
                </span>
              ))}
            </span>

            <span className="content">
              <span className="head">{items[currentItem].title}</span>
              <span className="text">{items[currentItem].content}</span>
            </span>
          </span>
        </span>
      </span>
    </div>
  )
}

export default RedLineChooser
