const BulletProof = () => (
  <span className="container-1200 z-index-100">
    <span className="data bulletproof">
      <h2 className="thin-end nowrap js-slide-up show">
        Remote is the new in-house, <br />
        only better
      </h2>
      <p className="js-slide-up show">
        Our software development company began operating more than 15 years ago,
        but the startup nerd within us is alive and well. While you’re taking
        care of good old business staff, we nail the wildest project up to a
        turnkey product, easily scaling your team upon your request
        and&nbsp;progress.
      </p>
    </span>
  </span>
)
export default BulletProof
