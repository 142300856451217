const HeaderBlock = ({
  projectname,
  description,
  period,
  subTitleTop,
  subTitleBottom,
  type
}) => {
  return (
    <div className="header-block">
      <h1>{projectname}</h1>
      <p>{description}</p>
      <div className="peculiar">
        <div className="peculiar_block">
          <div className="peculiar_number">{period}</div>
          <div className="peculiar_text">
            <div>{type}</div>
            <div>COOPERATION</div>
          </div>
        </div>
        <div className="peculiar_block">
          <div className="peculiar_multiple">
            <span>{subTitleTop}</span>
            <span>{subTitleBottom}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderBlock
