import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

let data = [
  {
    pname: 'Freshly',
    link: 'https://www.hopanatech.com/our-works/freshly/',
    text: 'FoodTech Ecommerce',
    alt: 'freshly_case_04',
    img: 'freshly_case_04.original.format-png.png'
  },
  {
    pname: 'ClassPass',
    link: 'https://www.hopanatech.com/our-works/classpass/',
    text: 'Wellness Fitness',
    alt: 'classpass_case_works-06',
    img: 'classpass_case_works-06.original.format-png.png'
  },
  {
    pname: 'Zefr',
    link: 'https://www.hopanatech.com/our-works/zefr/',
    text: 'AdTech Media',
    alt: 'zefr_case_06',
    img: 'zefr_case_06.original.format-png.png'
  }
]
const FeatureItem = ({ link, pname, text, alt, img }) => (
  <div>
    <span className="text">
      <a href={`${link}`}>
        <span className="project-name">{pname}</span>{' '}
        <span className="project-tags">
          <span>{text}</span>
          <span></span>
          <span className="tags_arrow">
            <img
              alt={alt}
              src={require(`assets/images/about/red_arrow.png`).default}
            />
          </span>
        </span>
      </a>
    </span>
    <picture>
      <img alt={alt} src={require(`assets/images/about/${img}`).default} />
    </picture>
  </div>
)

const FeatureWork = () => {
  return (
    <>
      <div className="data featured-works">
        <h2 className="line">
          <span>Featured</span>
          works
        </h2>
        <div className="tiles">
          {data.map((item, index) => (
            <FeatureItem key={index} {...item}></FeatureItem>
          ))}
        </div>

        <Swiper
          className="swiper-container-feature-works"
          slidesPerView={'auto'}
          loop={true}
          centeredSlides={true}
          setWrapperSize={true}
          preloadImages={false}
          onAfterInit={(swiper) => {
            setTimeout(() => {
              swiper.el.classList.remove('swiper-container') //hacky way to match to CSS file provided by oringal vendor
            })
          }}
        >
          {data.map(({ link, pname, text, alt, img }, idx) => (
            <SwiperSlide key={pname}>
              <a href={link}>
                <picture>
                  <source
                    srcSet={require(`assets/images/about/${img}`).default}
                    type="image/webp"
                  />
                  <img
                    alt={alt}
                    src={require(`assets/images/about/${img}`).default}
                  />
                </picture>
              </a>
              <span>
                <span>{pname}</span>{' '}
                <span className="project-tags">
                  <span>{text}</span>
                  <span></span>
                </span>
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default FeatureWork
