import { useDispatch, useSelector } from 'react-redux'
import { getTagActive } from "store/selectors/ui"
import { setBlogTag } from "store/actions/ui"

const Tag = ({ tag, title }) => {
    const active = useSelector(getTagActive(tag))
    const dispatch = useDispatch()

    const handleTap = () => dispatch(setBlogTag(tag, !active))
    return (
        <li data-tag={tag} onClick={handleTap} className={active ? 'active' : ''}>
            <div>{title}</div>
        </li>
    )
}

export default Tag