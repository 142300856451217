import React from 'react'
import { Link } from 'react-router-dom'

import { AUTH_PATHS } from 'constants/paths'
import { GLOBAL } from 'constants/app'

const Hero = () => {
  return (
    <div className="hero-block-large__wrapper">
      <video
        autoPlay
        loop
        muted
        playsInline
        poster={require('assets/videos/poster_blur-min.jpg').default}
        src={require('assets/videos/video_.mp4').default}
      >
        <source
          src={require('assets/videos/video_.mp4').default}
          type="video/mp4"
        />
        <source
          src={require('assets/videos/video_.webm').default}
          type="video/webm"
        />
        Sorry, your browser doesn't support embedded videos, but don't worry,
        you can <Link to="#">download it</Link> and watch it with your favorite
        video player!
      </video>
      <div className="block-content">
        <h1 className="block-title">You dream it, we build it</h1>
        <div className="block-description">
          {GLOBAL.COMPANY_NAME_LONG} is a one-stop source for custom software
          development. We provide VC-backed startups and fast-growing tech
          companies with dedicated engineering teams, delivering scalable
          products that users love.
        </div>
        <Link className="block-action-button" to={AUTH_PATHS.SIGN_UP}>
          Get started
        </Link>
      </div>
    </div>
  )
}

export default Hero
