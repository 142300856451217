import { useState, Fragment } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'

import Image1 from '../../assets/images/about/what_makes_us-01.original.png'
import Image3 from '../../assets/images/about/what_makes_us-03.original.png'
import Image4 from '../../assets/images/about/what_makes_us-04.original.png'
import Image5 from '../../assets/images/about/what_makes_us-05.original.png'
import Image6 from '../../assets/images/about/what_makes_us-06.original.png'

SwiperCore.use([Pagination, Navigation])

const data = [
  {
    img: Image1,
    desc: 'Invest in FF Ventures to support the early-stage tech companies'
  },
  {
    img: Image3,
    desc: 'Sponsor industry events to foster sharing expertise within the community'
  },
  {
    img: Image4,
    desc: 'Donate company resources to charitable causes'
  },
  {
    img: Image5,
    desc: 'Hiring with no regard for age, gender, and place of birth'
  },
  {
    img: Image6,
    desc: 'Promote and finance team networking and continuous learning culture'
  }
]

const MakesItem = ({ active, img, text, enter }) => (
  <div className={active ? 'active' : ''} onMouseEnter={enter}>
    <picture>
      <source srcSet={img} type="image/webp" />
      <img alt="Invest" src={img} />
    </picture>
    <div>{text}</div>
  </div>
)
const Makes = ({}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  return (
    <span className="container-1200">
      <span className="data">
        <h2 className="left">What makes us 'us'</h2>
        <div className="double-underline-block mobile-padding small_images">
          <div className="images">
            <div className="desktop full-width">
              {data.map((item, index) => (
                <MakesItem
                  key={index}
                  active={selectedIndex === index}
                  img={item.img}
                  text={item.desc}
                  enter={() => setSelectedIndex(index)}
                />
              ))}
            </div>
            <div className="mobile">
              <Swiper
                className="swiper-container-default"
                loop={false}
                slidesperPage={1}
                centeredSlides={true}
                setWrapperSize={true}
                preloadImages={false}
                onAfterInit={(swiper) => {}}
                // pagination={{
                //   clickable: false,
                //   renderBullet: function (index, className) {
                //     return (
                //       '<div>' +
                //       `<div class="${className}">${index < 10 ? '0' : ''}${
                //         index + 1
                //       }</div>` +
                //       '</div>'
                //     )
                //   }
                // }}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                }}
              >
                {data.map((item, index) => (
                  <SwiperSlide>
                    <picture>
                      <source srcSet={item.img} type="image/webp" />
                      <img alt="Invest" src={item.img} />
                    </picture>
                    <div>{item.desc}</div>
                  </SwiperSlide>
                ))}
                <div
                  className="swiper-button-prev"
                  tabindex="0"
                  role="button"
                  aria-label="Previous slide"
                  aria-disabled="false"
                ></div>
                <div
                  className="swiper-button-next"
                  tabindex="0"
                  role="button"
                  aria-label="Next slide"
                  aria-disabled="false"
                ></div>
              </Swiper>
            </div>
          </div>
          <div className="text">
            <div>
              <div className="head">
                Our strength and confidence is in our community
              </div>
              <div>
                The first rule of HopanaTech is: We do not talk about changes;
                we make changes. Our team always strives to disrupt and question
                the status quo, which is only possible with a strong, healthy,
                and cohesive local and international community.
              </div>
            </div>
            <div>
              <div className="head">We free the genius within everyone</div>
              <div>
                Our success is in connecting diverse skills, experiences, and
                backgrounds. HopanaTech is all about a positive, inclusive, and
                tolerant attitude, where employees have equal rights and chances
                to get all voices heard — in the full range. This means
                addressing all kinds of discrimination, no gender salary gaps,
                objective assessment criteria, and acceptance of alternative
                opinions.
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  )
}
export default Makes
