// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView
} from '@react-google-maps/api'
import { KEYS } from 'constants/app'

const defaultProps = {
  center: {
    lat: 30.266666,
    lng: -97.73333
  },
  zoom: 3
}

const mapStyles = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 45
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#3e3e3e'
      },
      {
        visibility: 'on'
      }
    ]
  }
]

const centerOverlayView = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2)
})

const AboutMap = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: KEYS.gmap
  })

  return (
    <span className="black invert no-margins">
      <div className="map-about-us-container">
        {isLoaded && (
          <GoogleMap
            id="map_about_us"
            zoom={defaultProps.zoom}
            center={defaultProps.center}
            options={{
              styles: mapStyles,
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              zoomControl: false
            }}
          >
            <OverlayView
              position={defaultProps.center}
              mapPaneName={OverlayView.MARKER_LAYER}
              getPixelPositionOffset={centerOverlayView}
            >
              <span class="pulsating_point">
                <span class="text usa">Texas</span>
              </span>
            </OverlayView>
          </GoogleMap>
        )}
      </div>
    </span>
  )
}

export default AboutMap
