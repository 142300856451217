import { Provider, ReactReduxContext } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'

import createStore from 'store'

import 'assets/styles/index.less'
import 'assets/styles/vendor/output.swiper.less'
import 'assets/styles/vendor/output.main.less'

import App from 'containers/app'

const { store, persistor, history } = createStore()

const Container = () => (
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)

export default Container
