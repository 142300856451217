import React from 'react'
import { Hero, CoreValue, Partners, Portfolio } from 'components/home'
import TilesWithLinksWrapper from 'components/common/tiles-with-links'
import { Contact } from 'components/common'
import Testimonials from 'components/home/testimonials'

const Home = () => {
  return (
    <div className="page-home home page">
      <div className="main-block">
        <Hero />
        <TilesWithLinksWrapper />
        <CoreValue />
        <Partners />
        <Testimonials />
        <Portfolio />
      </div>
      <Contact />
    </div>
  )
}

export default Home
