
import React from 'react'

const Reports = () => {
  return (
    <div className="page-client-reports">
      Reports
    </div>
  )
}

export default Reports