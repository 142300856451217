
import React from 'react'
import Gallery from 'components/portfolio/gallery'

const Portfolio = () => {
  return (
    <div className="page-portfolio">
      <Gallery/>
    </div>
  )
}

export default Portfolio