
import React from 'react'

const Messages = () => {
  return (
    <div className="page-client-messages">
      Messages
    </div>
  )
}

export default Messages