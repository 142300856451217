import Tag from './tag'

const Tags = () => {
  return (
    <div className="tags js-blog-tags">
      <ul>
        <Tag tag="insights" title="Insights" />
        <Tag tag="community" title="Community" />
        <Tag tag="success_stories" title="Success stories" />
        <Tag tag="events" title="Events" />
      </ul>
    </div>
  )
}

export default Tags
