import { createContext, useState } from 'react'

import TileItem from './tile-item'

export const TilesWithLinksContext = createContext({})

const data1 = {
  type: 1,
  categories: [
    {
      title: 'Web',
      content:
        'Our high-performing, secure web applications deliver tangible, immediate business results',
      icon: require('assets/svg/web_dev.svg').default,
      link: '#'
    },
    {
      title: 'Mobile',
      content:
        'Native, hybrid, or cross-platform, our mobile apps leverage the latest features and functionality',
      icon: require('assets/svg/mob_dev.svg').default,
      link: '#'
    },
    {
      title: 'Cloud',
      content:
        'From migrating existing infrastructure to building from scratch, our cloud experts are up to the task',
      icon: require('assets/svg/cloud_dev.svg').default,
      link: '#'
    }
  ]
}

const TilesWithLinksWrapper = ({ _ }) => {
  const [activeIndex, setActiveIndex] = useState(undefined)

  const { type, categories } = data1

  const getItem = (category, idx) => {
    switch (type) {
      default:
        return <TileItem item={category} index={idx} key={'tile-item-' + idx} />
    }
  }

  return (
    <TilesWithLinksContext.Provider value={{ activeIndex, setActiveIndex }}>
      <div className="tiles-with-links__wrapper">
        <div className="block-content">
          <h2 className="block-title show">
            We speak your <span>language</span>
          </h2>
          <div className="block-description">
            Whatever your product vision, our engineers possess the stack
            fluency and platform knowledge to bring it to life. For more than 15
            years, our team has remained on the forefront of innovation,
            implementing solutions to suit even the most unique needs.
          </div>
          <div className="tiles-container">
            {categories.map((category, idx) => getItem(category, idx))}
          </div>
        </div>
      </div>
    </TilesWithLinksContext.Provider>
  )
}

export default TilesWithLinksWrapper
