import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Autoplay } from 'swiper'

SwiperCore.use([EffectFade, Autoplay])

const projects = [
  {
    show_title: 'Case study',
    title: 'Rally',
    link: '/our-works/rally',
    cover: require('assets/img/projects/rally.png').default,
    summary:
      'We assembled a first-class custom software development team that helped Rally develop multifunctional SPA, high-performing backend API, and a native iOS application',
    tags: ['Automotive', 'FinTech']
  },
  {
    show_title: 'Case study',
    title: 'ClassPass',
    link: '/our-works/classPass',
    cover: require('assets/img/projects/classpass.png').default,
    summary:
      'For ClassPass, the leading fitness workout membership, our team developed feature-rich web solutions as well as iOS and Android native mobile apps with powerful functionality across user and venue management',
    tags: ['Wellness', 'Fitness']
  },
  {
    show_title: 'Case study',
    title: 'Bevi',
    link: '/our-works/bevi',
    cover: require('assets/img/projects/bevi.png').default,
    summary:
      'We built an Android-powered solution that synchronizes with Bevi’s hardware module and manages the functionality of smart water coolers',
    tags: ['Food and Beverage']
  }
]

const Portfolio = () => {
  const swiperRef = useRef(null)

  return (
    <div className="portfolio__wrapper">
      <h2 className="block-title__mobile">Case study</h2>
      <Swiper
        className="block-content"
        spaceBetween={0}
        effect="fade"
        fadeEffect={{
          crossFade: true
        }}
        autoplay={true}
        loop={true}
        slidesPerView={1}
        //pagination={{ clickable: true }}
        onSlideResetTransitionEnd={() =>
          console.log('onSlideResetTransitionEnd')
        }
        // onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {projects.map(
          ({ show_title, title, link, cover, summary, tags }, idx) => (
            <SwiperSlide className="project-wrapper">
              <div className="block-content">
                <div className="project-info">
                  <h2 className="block-title show">{show_title}</h2>
                  <div className="project-title">
                    <a href={link}>{title}</a>
                  </div>
                  <div className="project-summary">{summary}</div>
                  <div className="swiper-buttons">
                    <div
                      className="swiper-button-prev"
                      tabIndex="0"
                      role="button"
                      aria-label="Previous slide"
                      onClick={() => {
                        swiperRef.current.slidePrev()
                      }}
                    ></div>
                    <div
                      className="swiper-button-next"
                      tabIndex="1"
                      role="button"
                      aria-label="Next slide"
                      onClick={() => {
                        swiperRef.current.slideNext()
                      }}
                    ></div>
                  </div>
                  <div className="project-tags">
                    {tags.map((tag, idx) => (
                      <span>{tag}</span>
                    ))}
                  </div>
                </div>
                <div className="project-cover">
                  <img alt="" src={cover} />
                </div>
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  )
}

export default Portfolio
