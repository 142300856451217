import {
  LeftStackText,
  ContainerNode,
  BlackInvertStep,
  BlackWay,
  PerkWithTeam
} from 'components/taas'
import { HeroBlock, Contact, FeatureWork } from 'components/common'

const HowWeWork = () => {
  return (
    <div className="article page">
      <HeroBlock
        content="Team as a Service"
        summary="HopanaTech provides senior-level talent to help startups and fast-moving tech companies quickly start and scale their product development. Our dedicated development team will seamlessly integrate with your in-house engineers while using the same tools and methodologies and embracing the same&nbsp;mindset."
        button="Get started"
      />
      <LeftStackText />
      <ContainerNode />
      <BlackInvertStep />
      <BlackWay />
      <PerkWithTeam />
      <FeatureWork />
      <Contact />
    </div>
  )
}

export default HowWeWork
