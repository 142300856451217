const Block = ({ title, description, imgDetail }) => {
  return (
    <div className={!imgDetail.isRight ? 'right-block' : 'left-block'}>
      {!imgDetail.isRight && (
        <picture>
          <img alt="bevi-04" src={imgDetail.imgUrl} />
        </picture>
      )}
      <span>
        <span className="project-header">{title}</span>
        <p></p>
        {description.text && description.text.map((item) => <p>{item}</p>)}
        {description.category && (
          <ul>
            {description.category.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        )}
        <br />
        <br />
        <p></p>
      </span>
      {imgDetail.isRight && (
        <picture>
          <img alt="bevi-04" src={imgDetail.imgUrl} />
        </picture>
      )}
    </div>
  )
}

export default Block
