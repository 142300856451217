import { React } from 'react'

import PerkItem from './perk-item'

const data = {
  categories: [
    {
      title: 'Minimum extras',
      content:
        'Forget about all the mind-boggling yet important stuff like ergonomic chairs, massage, and other add-ons employees might actually not need. You have it covered from every angle.'
    },
    {
      title: 'Reliable collab',
      content:
        'We build a strong, durable environment, but our collaboration doesn’t end there. You can be sure that your team is ready to stay with you as long as you need them.'
    },
    {
      title: 'Quick ramp-up',
      content:
        'With HopanaTech’s staff augmentation services, you can easily scale your dedicated team up as your business grows. It’s like you are managing your in-house team, only more flexible.'
    }
  ]
}

const PerkItemsWrapper = () => {
  const { categories } = data

  const getItem = (category, idx) => {
    return <PerkItem item={category} index={idx} key={'item' + idx} />
  }

  return (
    <span className="flexbox list-items-type-2 double">
      {categories.map((category, idx) => getItem(category, idx))}
    </span>
  )
}

export default PerkItemsWrapper
