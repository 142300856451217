import _ from 'lodash'

import { isNumOrStr } from './DataUtils'

export const getValueByDataKey = (obj, dataKey, defaultValue) => {
  if (_.isNil(obj) || _.isNil(dataKey)) {
    return defaultValue
  }

  if (isNumOrStr(dataKey)) {
    return _.get(obj, dataKey, defaultValue)
  }

  if (_.isFunction(dataKey)) {
    return dataKey(obj)
  }

  return defaultValue
}
