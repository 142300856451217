import React from 'react'
import RedUnderlineChooser from 'components/common/red-underline-chooser'

const BlackInvertStep = () => {
  return (
    <span className="black invert left margin-bottom-0">
      <span className="container-1200">
        <span className="data">
          <h2>
            Welcome to <br /> a remote talent hub
          </h2>
          <span>
            We are serious about your success from the get-go. HopanaTech
            ensures your in-house team and our dedicated developers enter
            synergy mode and bring the heat to your target market.
          </span>
          <RedUnderlineChooser />
        </span>
      </span>
    </span>
  )
}

export default BlackInvertStep
