import PieChartContainer from './PieChart/PieChart'
import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

const Percent = ({ value, level, shown }) => (
  <span className={shown ? 'show' : ''}>
    <span>{value}%</span>
    <span>{level}</span>
  </span>
)

const PieChartBlock = ({}) => {
  const [isShownVertical, setIsShownVertical] = useState(false)

  return (
    <span className="pie-chart-block container-1200">
      <span>
        <h2>Dedicated engineers, hand-picked</h2>
        <p>
          HopanaTech promotes the post-rock star development culture — here, you
          won’t find divas and prima donnas. We hire go-ahead engineers who
          embrace the game-changing mindset of our clients and whose
          star-studded performance relates mostly to deliverables.
        </p>
        <VisibilitySensor
          onChange={(isVisible) => {
            if (isVisible) {
              setIsShownVertical(true)
            }
          }}
        >
          <span className="vertical">
            <Percent value={70} level="Seniors" shown={isShownVertical} />
            <Percent value={20} level="Middles" shown={isShownVertical} />
            <Percent value={10} level="Juniors" shown={isShownVertical} />
          </span>
        </VisibilitySensor>
      </span>
      <span>
        <PieChartContainer />
      </span>
    </span>
  )
}
export default PieChartBlock
