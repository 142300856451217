import React, {useState} from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import CoreValueItem from './core-value-item'
import RedLineChooser from 'components/common/red-line-chooser/red-line-chooser'

const data = [
  {
    titleBold: 'Attract top',
    titleRed: 'talent',
    stats: [
      { value: '1.7k', description: 'experienced \nengineers' },
      { value: '5.6%', description: `attrition rate, the \nindustry's lowest` }
    ]
  },
  {
    titleBold: 'Partner with',
    titleRed: 'the best',
    stats: [
      { value: '60%', description: 'of clients come from top accelerators' },
      { value: '30+', description: 'diverse industries \nwe serve' }
    ]
  },
  {
    titleBold: 'Act with',
    titleRed: 'speed',
    stats: [
      { value: '30%', description: 'less time \nto market' },
      { value: '3', description: 'weeks from contact \nto kick-off' }
    ]
  },
  {
    titleBold: 'Celebrate client',
    titleRed: 'success',
    stats: [
      { value: '$12.2B', description: 'in acquisitions' },
      { value: '15', description: 'IPOs date' }
    ]
  }
]

const CoreValue = () => {
  const [isShownHeader, setIsShownHeader] = useState(false)

  return(
    <div className="article awesome left-align">
      <span className="data grey">
        <VisibilitySensor onChange={isVisible => {
          if(isVisible) {
            setIsShownHeader(true)
          }
        }}>
          <span className="container-1200">
            <h2 className={isShownHeader ? "show" : ""}>Proven process, real results</h2>
            <p>
              Our success starts with sourcing best-in-class developers and ends
              with exceeding our clients' every&nbsp;expectation.
            </p>
          </span>
        </VisibilitySensor>
        
        <span className="grey-50">
          <span className="container-1200">
            <span className="flexbox items-flex single-mobile-columns col-2">
              {data.map((item, index) => (
                <CoreValueItem key={`core-value-item${index}`} {...item} />
              ))}
            </span>
          </span>
        </span>
      </span>

      <RedLineChooser />
    </div>
  )
}

export default CoreValue
