import { useState } from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView
} from '@react-google-maps/api'
import { KEYS } from 'constants/app'

const dataOffices = [
  {
    position: { lat: 40.757, lng: -73.971266 },
    address: {
      country: 'USA',
      city: 'New York',
      address1: '575 Lexington Avenue, 14th Floor',
      address2: 'New York, NY 10022',
      postalCode: '10022',
      phone: '732 734 6525'
    }
  },
  {
    position: { lat: 37.7569655, lng: -122.4225558 },
    address: {
      country: 'USA',
      city: 'San Francisco',
      address1: '650 California Street',
      address2: 'San Francisco, CA 94108',
      postalCode: '94108'
    }
  },
  {
    position: { lat: 33.979821, lng: -84.448898 },
    address: {
      country: 'USA',
      city: 'Marietta',
      address1: '3535 Roswell Road, Suite 40',
      address2: 'Marietta, GA 30062',
      postalCode: '30062',
      phone: '678 433 1465'
    }
  }
]

const mapStyles = [
  {
    featureType: 'all',
    stylers: [{ saturation: 0 }, { hue: '#e7ecf0' }]
  },
  { featureType: 'road', stylers: [{ saturation: -70 }] },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }]
  },
  { featureType: 'poi', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'water',
    stylers: [{ visibility: 'simplified' }, { saturation: -60 }]
  }
]

const centerOverlayView = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2)
})

const MapContainer = (_) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: KEYS.gmap
  })

  const [activeOfficeIdx, setActiveOfficeIdx] = useState(0)

  return (
    <div className="maps">
      <div className="map-item active">
        {isLoaded && (
          <GoogleMap
            id="map-contact-us"
            zoom={7}
            center={dataOffices[activeOfficeIdx].position}
            options={{
              styles: mapStyles,
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              zoomControl: false
            }}
          >
            {dataOffices.map((office, index) => (
              <OverlayView
                key={index}
                position={office.position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={centerOverlayView}
              >
                <span
                  class="pulsating_point"
                  onClick={() => {
                    setActiveOfficeIdx(index)
                  }}
                >
                  <span class="text usa">{office.address.city}</span>
                </span>
              </OverlayView>
            ))}
          </GoogleMap>
        )}
      </div>
      <div className="controls" style={{ opacity: 1 }}>
        <div className="container-1440 flexbox">
          {dataOffices.map((office, index) => (
            <div
              className={
                'controls-item' + (index === activeOfficeIdx ? ' active' : '')
              }
              data-id={index}
              data-lat={office.position.lat}
              data-lng={office.position.lng}
              key={index}
              onClick={() => {
                setActiveOfficeIdx(index)
              }}
            >
              <div>
                <div>{office.address.country}</div>
                <h2>{office.address.city}</h2>
              </div>
              <div className="address-details">
                <span>
                  <p>{office.address.address1}</p>
                  <p>{office.address.address2}</p>

                  {office.address.phone && (
                    <p>
                      Phone:{' '}
                      <a href={`tel:${office.address.phone}`}>
                        {office.address.phone}
                      </a>
                    </p>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MapContainer
