import React from 'react'

const PerkItem = ({ item, index }) => {
  const { title, content } = item

  return (
    <span className="item">
      <span>{title}</span>
      <span>{content}</span>
    </span>
  )
}

export default PerkItem
