import { useEffect } from 'react'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import routes from 'config/routes'
import { getIsReady } from 'store/selectors/app'
import { getVisitorRole } from 'store/selectors/accounts'

import 'assets/styles/index.less'
import { signInByToken } from 'store/actions/auth'
import { getError, getIsLoaded, getIsLoading } from 'store/selectors/auth'
import { Loading, ScrollToTop } from 'components/common'
import { getHasToken } from 'store/selectors/persist'

let App = ({ role, isReady, isSigningIn, hasToken, autoSignIn }) => {
  useEffect(() => {
    autoSignIn && autoSignIn()
  }, [autoSignIn])

  return (
    <div className="App">
      {isSigningIn || (hasToken && !isReady) ? (
        <Loading />
      ) : (
        <BrowserRouter>
          <ScrollToTop />
          {renderRoutes(routes({ isReady, role }))}
        </BrowserRouter>
      )}
    </div>
  )
}

export default connect(
  createStructuredSelector({
    error: getError,
    isSigningIn: getIsLoading,
    isSignedIn: getIsLoaded,
    isReady: getIsReady,
    role: getVisitorRole,
    hasToken: getHasToken
  }),
  {
    autoSignIn: signInByToken
  }
)(App)
