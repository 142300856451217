import React from 'react'
import { Row, Col } from 'antd'

import ContactCustomizedFile from './contact-customized-file'
import { FixedWidthLayout } from 'containers/layout'

const Contact = () => {
  return (
    <div className="contact-form">
      <form>
        <div className="content">
          <div className="center">
            <h2 className="js-anchor show" id="contact_us">
              Get in touch
            </h2>
            <span></span>
          </div>
          <div className="error hidden">
            <div className="message"></div>
          </div>
          <div className="form-blocks">
            <div className="left-form-block">
              <div>
                <div className="require">
                  <input
                    id="name"
                    name="name"
                    placeholder="First name"
                    type="text"
                  />
                  <label htmlFor="name"></label>
                </div>
                <div className="require">
                  <input
                    id="surname"
                    name="surname"
                    placeholder="Last name"
                    type="text"
                  />
                  <label htmlFor="surname">*</label>
                </div>
                <div className="on-focus">
                  <div className="require">
                    <input
                      autoComplete="off"
                      id="email"
                      name="email"
                      placeholder="Email address"
                      type="text"
                    />
                    <div className="tool-tip bottom slideIn">
                      We will use your e-mail for the purpose of contacting you
                    </div>
                    <label htmlFor="email">*</label>
                  </div>
                </div>
                <input
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  type="text"
                />
              </div>
              <div className="row file">
                <input
                  className="hidden file"
                  id="file-0"
                  name="file"
                  type="file"
                />
                <label className="attachment" htmlFor="file-0">
                  <span className="text">Upload file (max size is 30 MB)</span>
                  <div className="file-plus">
                    <span>+</span>
                  </div>
                </label>
              </div>
            </div>
            <div className="right-form-block">
              <div>
                <div className="require">
                  <input
                    autoComplete="off"
                    id="budget"
                    name="budget"
                    placeholder="Pre-estimated budget"
                    type="text"
                  />
                  <label htmlFor="budget">*</label>
                </div>
                <div className="row show-all-content">
                  <textarea
                    cols="1"
                    id="message"
                    name="message"
                    placeholder="Type your message"
                  ></textarea>
                </div>
                <div
                  className="row privacy privacy-landing"
                  style={{ display: 'inline-block' }}
                >
                  <div>
                    <input
                      id="privacy41782050017715951"
                      name="privacy"
                      type="checkbox"
                      value="pcp"
                    />
                    <label htmlFor="privacy41782050017715951">
                      I agree to the&nbsp;
                      <a
                        className="privacy-policy"
                        href="/privacy-policy-itechart/"
                        rel="nofollow"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      &nbsp;and give my permission to process my personal data
                      for the purposes specified in the Privacy Policy
                    </label>
                  </div>
                </div>
                <div className="buttons">
                  <button id="submit_button" type="submit">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    // <FixedWidthLayout className='contact-form'>
    //   <h2 className="head-text">Contact us</h2>
    //   <form>
    //     <Row>
    //       <Col md={12} xs={24} className="form-part">
    //         <div className="require">
    //           <input id="name" name="name" placeholder="First name" type="text"/>
    //           <label htmlFor="name">*</label>
    //         </div>
    //         <div className="require">
    //           <input id="surname" name="surname" placeholder="Last name" type="text"/>
    //           <label htmlFor="surname">*</label>
    //         </div>
    //         <div className="require">
    //           <input autoComplete="off" id="email" name="email" placeholder="Email address" type="text"/>
    //           <label htmlFor="email">*</label>
    //         </div>
    //         <input id="phone" name="phone" placeholder="Phone" type="text"/>
    //         {/* <input type="file" placeholder="Upload file (max size is 30MB)"/> */}
    //         <ContactCustomizedFile />
    //       </Col>
    //       <Col md={12} xs={24} className="form-part">
    //         <div className="require">
    //           <input autoComplete="off" id="budget" name="budget" placeholder="Pre-estimated budget" type="text"/>
    //           <label htmlFor="budget">*</label>
    //         </div>
    //         <textarea rows="3" placeholder="Type your message">
    //         </textarea>
    //         <div className="d-flex justify-content-center">
    //           <button className="send-button">Send</button>
    //         </div>
    //       </Col>
    //     </Row>
    //   </form>
    // </FixedWidthLayout>
  )
}

export default Contact
