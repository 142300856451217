import { React } from 'react'

import DataItem from './data-item'

const data1 = {
  categories: [
    {
      title: 'Top',
      subTitle: 'tier talent',
      content:
        'HopanaTech ramped up our in-house team with 1700 engineers under one roof to ensure the development process is seamless and transparent.'
    },
    {
      title: 'Engineering',
      subTitle: 'powerhouse',
      content:
        'You get unlimited access to all the skills you might need, including web, mobile, QA and testing, big data and DevOps.'
    },
    {
      title: 'Great',
      subTitle: 'teamwork',
      content:
        'We at HopanaTech consider ourselves as one big team, not just a number of units — so when we join your project, they’ll be on the same page about the upcoming gig from day one.'
    },
    {
      title: 'Startup',
      subTitle: 'mentaility',
      content:
        'Our people are thrilled to tackle hot technologies and challenging tasks. Sounds exactly like your project, huh?'
    }
  ]
}

const DataItemsWrapper = () => {
  const { categories } = data1

  const getItem = (category, idx) => {
    return <DataItem item={category} index={idx} key={'item' + idx} />
  }

  return (
    <span className="flexbox items-flex single-mobile-columns col-2">
      {categories.map((category, idx) => getItem(category, idx))}
    </span>
  )
}

export default DataItemsWrapper
