import React from 'react'
const LeftStackText = () => {
  return (
    <span className="left-static-text" style={{ top: '480px' }}>
      <span>since 2002</span>
      <span className="line">————</span>
      <span>All-in-one software teams</span>
      <span className="line">————</span>
      <span>1700+ Devs</span>
    </span>
  )
}

export default LeftStackText
