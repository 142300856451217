import React from 'react'
import TechCounter from 'components/common/tech-counter'

const BlackWay = () => {
  return (
    <span className="black">
      <span className="container-1440">
        <span className="data">
          <h3>We make it your way</h3>
          <span>
            Our dedicated developers come as a 100% extension of your on-site
            team. They are fully-synced and always available for a chat, whether
            it’s a daily standup, a code review or an important decision to be
            made. And if needed, they’ll be happy to drop by.
          </span>
          <TechCounter />
        </span>
      </span>
    </span>
  )
}

export default BlackWay
