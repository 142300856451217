
import BlogTags from 'components/blog/tags'
import BlogList from 'components/blog/list'
import React from 'react'

const Blog = () => {
  return (
    <div className="blog">
      <div className="container-1440">
        <h1>Hopana blog</h1>
      </div>
      <div className="projects flexbox">
        <BlogTags />
        <BlogList />
      </div>
    </div>
  )
}

export default Blog