import React, { useState } from 'react'
import { useInterval } from 'utils/hook'

const TypeItCaret = ({ strings }) => {
  const [currentString, setCurrentString] = useState('')
  const [curId, setCurId] = useState(0)
  const [sqeId, setSeqId] = useState(0)

  const interval = 30
  let timercnt = 0

  const sequences = [
    {
      step: 3,
      stringadd: 1
    },
    {
      step: 50,
      stringadd: 0
    },
    {
      step: 1,
      stringadd: -1
    },
    {
      step: 40,
      stringadd: 0
    }
  ]

  useInterval(() => {
    timercnt++
    const seq = sequences[sqeId]
    if (timercnt % seq['step'] !== 0) {
      return
    }
    let seq_finished = false
    if (seq['stringadd'] === 1) {
      if (currentString.length < strings[curId].length) {
        let newchar = strings[curId][currentString.length]
        setCurrentString(currentString + newchar)
      } else {
        seq_finished = true
      }
    } else if (seq['stringadd'] === 0) {
      seq_finished = true
    } else if (seq['stringadd'] === -1) {
      if (currentString.length === 0) {
        seq_finished = true
        setCurId((curId + 1) % strings.length)
      } else {
        setCurrentString(currentString.substr(0, currentString.length - 1))
      }
    }

    if (seq_finished) {
      setSeqId((sqeId + 1) % sequences.length)
      timercnt = 0
    }
  }, interval)

  return <span id="caret-string">{currentString}</span>
}

export default TypeItCaret
