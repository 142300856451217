import {
  BulletProof,
  AboutMap,
  Software,
  PieChartBlock,
  History,
  TopTalent,
  Makes
} from 'components/about'
import { HeroBlock } from 'components/common'
import { Contact } from 'components/common'
import { FeatureWork } from 'components/common'

const AboutPage = ({ _ }) => (
  <div className="page-about-us">
    <div className="article page">
      <HeroBlock
        content="Hey, we’re HopanaTech"
        summary="We assemble senior-level, dedicated teams of developers to help fast-growing startups and innovative enterprises drive impact and achieve their goals."
      />
      <BulletProof />
      <AboutMap />
      <Software />
      <PieChartBlock />
      <History />
      <TopTalent />
      <Makes />
      <FeatureWork />
      <Contact />
    </div>
  </div>
)
export default AboutPage
