import { useState } from 'react'

const budgets = [
  { label: 'less than 50k', value: '50-k' },
  { label: '$50-80k', value: '50-80k' },
  { label: '$80-150k', value: '80-150k' },
  { label: 'more than $150k', value: '150+k' }
]

const interests = [
  { label: 'MVP development', key: 'mvp' },
  { label: 'Ongoing development', key: 'ongoing_dev' },
  { label: 'Maintenance/Support', key: 'maintenance_support' },
  { label: 'Turnkey solution', key: 'turnkey_solution' },
  { label: 'Careers at HopanaTech', key: 'careers' },
  { label: 'Other', key: 'other' }
]

const Form = (_) => {
  const [name, setName] = useState('')
  const [surName, setSurName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [budgetIndex, setBudgetIndex] = useState(0)

  const handleBudgetClick = (index) => {
    setBudgetIndex(index)
  }

  return (
    <div className="form">
      <div className="vertical-slider-item contact-form">
        <form>
          <div className="center">
            <h1 className="">Contact us</h1>
          </div>
          <div className="error hidden">
            <div className="message"></div>
          </div>
          <div>
            <input
              autocomplete="off"
              id="name"
              name="name"
              placeholder="First name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              autocomplete="off"
              id="surname"
              name="surname"
              placeholder="Last name"
              type="text"
              value={surName}
              onChange={(e) => setSurName(e.target.value)}
            />
            <input
              autocomplete="off"
              id="phone"
              name="phone"
              placeholder="Phone"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="on-focus">
              <input
                autocomplete="off"
                id="email"
                name="email"
                placeholder="Email address*"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="tool-tip bottom slideIn">
                We will use your e-mail for the purpose of contacting you
              </div>
            </div>
          </div>
          <h3>What are you looking for?</h3>
          <div className="interests">
            {interests.map((interest, index) => (
              <div key={interest.key}>
                <input
                  id={interest.key}
                  name={`interest[${interest.key}]`}
                  type="checkbox"
                  value={interest.key}
                />
                <label for={interest.key}>{interest.label}</label>
              </div>
            ))}
          </div>
          <h3 className="budget-line-head">What budget do you have in mind?</h3>
          <div className="budget-line">
            <hr className="budget" />
            <div>
              {budgets.map((budget, index) => (
                <div
                  className={
                    'red-budget-circle' +
                    (index === budgetIndex ? ' active bg-fill' : '')
                  }
                  style={{ left: `${(100 * index) / (budgets.length - 1)}%` }}
                  key={index}
                  onClick={() => handleBudgetClick(index)}
                >
                  <span>{budget.label}</span>
                  <input
                    className="js-no-arrow-min"
                    name="budget"
                    type="radio"
                    value={budget.value}
                  />
                </div>
              ))}
            </div>
          </div>
          <h3>Want to add anything?</h3>
          <div className="row show-all-content">
            <textarea
              id="message"
              name="message"
              placeholder="Type your message*..."
            ></textarea>
          </div>
          <div className="row">
            <input
              className="hidden file"
              id="file-0"
              name="file"
              type="file"
            />
            <label className="attachment" for="file-0">
              <span
                className="text"
                data-default-text="Upload file (max file size is 30MB)"
              >
                Upload file (max file size is 30MB)
              </span>
              <div className="file-plus">
                <span>+</span>
              </div>
            </label>
          </div>
          <div className="row privacy" style={{ display: 'inline-block' }}>
            <div>
              <input id="privacy" name="privacy" type="checkbox" value="pcp" />
              <label for="privacy">
                I agree to the{' '}
                <a
                  className="privacy-policy"
                  href="/privacy-policy-itechart/"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                and give my permission to process my personal data for the
                purposes specified in the Privacy Policy
              </label>
            </div>
          </div>
          <div className="buttons">
            <button id="submit_button" type="submit">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Form
