import React, { useState, useRef, Fragment } from 'react'
import { useInterval, useWindowDimensions } from 'utils/hook'

import Image1 from '../../assets/images/how-we-work/your-way/ded_team_1-01.original.png'
import Image2 from '../../assets/images/how-we-work/your-way/ded_team_2-02.original.png'
import Image3 from '../../assets/images/how-we-work/your-way/ded_team-edition-04.original.png'
import { WINDOWWIDTH } from 'constants/app'

const items = [
  {
    imgURL: Image1,
    title: 'Run the team your way',
    strAlt: 'ded_team_1'
  },
  {
    imgURL: Image2,
    title: 'Augment an in-house team',
    strAlt: 'ded_team_2'
  },
  {
    imgURL: Image3,
    title: 'Lead a large-scale project',
    strAlt: 'ded_team_3'
  }
]

const TechCounter = () => {
  const [currentItem, setCurrentItem] = useState(0)
  const [currentItemTarget, setCurrentItemTarget] = useState(-1)
  const [barHeight, setBarHeight] = useState(0)
  const [barTop, setBarTop] = useState(0)
  const [redBarStatus, setRedBarStatus] = useState(0)
  const [imageOpacity, setImageOpacity] = useState(1)

  const barRef = useRef(null)
  const redBarHRat = 0.25

  const { winheight, winwidth } = useWindowDimensions()

  useInterval(
    () => {
      setImageOpacity(imageOpacity + 0.1 * redBarStatus)
      if (imageOpacity <= 0 && redBarStatus === -1) {
        setRedBarStatus(1)
        setCurrentItem(currentItemTarget)
      } else if (imageOpacity >= 1 && redBarStatus === 1) {
        setRedBarStatus(0)
      }
    },
    redBarStatus === 0 ? null : 20
  )

  setTimeout(() => {
    if (barRef.current != null) {
      setBarHeight(barRef.current.clientHeight)
      setBarTop(barRef.current.offsetTop)
    }
  }, 1)

  const onItemActive = (idx) => {
    if (idx !== currentItemTarget) {
      setCurrentItemTarget(idx)
      setImageOpacity(1)
      setRedBarStatus(-1)
    }
  }

  const onItemOver = (idx) => {
    if (winwidth > WINDOWWIDTH.mobile) {
      onItemActive(idx)
    }
  }

  const onItemClick = (idx) => {
    onItemActive(idx)
  }

  return (
    <span className="tech-counter img">
      <span className="left">
        <span className="items">
          {items.map(({ title, imgURL, strAlt }, idx) => (
            <Fragment key={strAlt}>
              <span
                className={idx === currentItem ? 'in_action' : ''}
                onMouseOver={() => onItemOver(idx)}
                onClick={() => onItemClick(idx)}
                data-text={`img src='${imgURL}') alt='${strAlt}'`}
              >
                {title}
              </span>
              {idx === currentItem && (
                <p className="mobile-description">
                  <img src={imgURL} alt={strAlt} />
                </p>
              )}
            </Fragment>
          ))}
        </span>
      </span>
      <span className="right">
        <span className="numbers">
          <span className="red">1</span>/<span className="totla">3</span>
        </span>
        <span className="text" style={{ opacity: imageOpacity }}>
          <img
            src={items[currentItem].imgURL}
            alt={items[currentItem].strAlt}
          />
        </span>
        <span className="vl" ref={barRef}></span>
        <span
          className="vl_red"
          style={{
            opacity: imageOpacity,
            top:
              barTop +
              (currentItem === 0
                ? 0
                : currentItem === 1
                ? (barHeight * (1 - redBarHRat)) / 2
                : barHeight * (1 - redBarHRat)),
            height: barHeight * redBarHRat,
            transition: 'all 0.2 linear 0.2'
          }}
        ></span>
      </span>
    </span>
  )
}

export default TechCounter
