import React, {useState} from 'react'
import VisibilitySensor from 'react-visibility-sensor'

const CoreValueItem = ({ titleBold, titleRed, stats }) => {
  const [isShownItem, setIsShownItem] = useState(false)

  return (
    <VisibilitySensor onChange={isVisible => {
      if(isVisible) {
        setIsShownItem(true)
      }
    }}>
      <span className={`item ${isShownItem ? "show" : ""}`}>
        <span className="head">
          <span className="bold">{titleBold}</span>
          <span className="red">{titleRed}</span>
        </span>
        <span className="vertical">
          {stats[0] && (
            <span className={isShownItem ? "show" : ""}>
              <span>{stats[0].value}</span>
              <span>{stats[0].description}</span>
            </span>
          )}
          {stats[1] && (
            <span className="delay-2 show">
              <span>{stats[1].value}</span>
              <span>{stats[1].description}</span>
            </span>
          )}
        </span>
      </span>
    </VisibilitySensor>
  )
}

export default CoreValueItem
