import React, { useState, Fragment } from 'react'
import { getValueByDataKey } from './ChartUtils'
import { mathSign, isNumber } from './DataUtils'

import Layer from './Layer'
import Surface from './Surface'
import Sector from './Sector'

const chartData = [
  {
    name: 'Backend',
    value: 27,
    color: '#ff0000',
    stroke: '#ff0000',
    labelColor: '#ffffff',
    tech: ['Python', 'Node', 'RoR', 'Go', 'PHP', 'Scala', 'Java', '.Net']
  },
  {
    name: 'Frontend',
    value: 24,
    color: '#ffffff',
    stroke: '#ff0000',
    tech: [
      'React',
      'Node',
      'Angular',
      'Vue',
      'Bootstrap',
      'Knockout',
      'Backbone',
      'Ionic'
    ]
  },
  {
    name: 'DevOps',
    value: 8,
    color: '#999999',
    stroke: '#999999',
    tech: ['Terraform', 'Ansible', 'Kubernetes', 'Docker', 'CI/CD']
  },
  {
    name: 'QA',
    value: 16,
    color: '#DDDDDD',
    stroke: '#DDDDDD',
    tech: ['Manual', 'Automation']
  },
  {
    name: 'Cloud',
    value: 11,
    color: '#999999',
    stroke: '#999999',
    tech: ['AWS', 'GCP', 'Azure', 'Cloud Native']
  },
  {
    name: 'Mobile',
    value: 14,
    color: '#ffffff',
    stroke: '#ff0000',
    tech: ['Cross-platform', 'iOS', 'Android']
  }
]

const chartRadius = 200

const parseDeltaAngle = (startAngle, endAngle) => {
  const sign = mathSign(endAngle - startAngle)
  const deltaAngle = Math.min(Math.abs(endAngle - startAngle), 360)

  return sign * deltaAngle
}

const PieChart = ({ pieData, activeIndex, handleActiveIndexChange }) => {
  const realDataKey = 'value'
  const nameKey = 'name'

  let max = 0
  let sum = 0
  pieData.forEach((entry) => {
    const r_val = getValueByDataKey(entry, realDataKey, 0)
    const val = isNumber(r_val) ? r_val : 0
    sum += val
    if (max < val) {
      max = val
    }
  })

  let prev
  const cornerRadius = 0
  const len = pieData.length
  const startAngle = 90
  const endAngle = -270
  const deltaAngle = parseDeltaAngle(startAngle, endAngle)
  const minAngle = 0
  const paddingAngle = 1
  const absDeltaAngle = Math.abs(deltaAngle)
  const totalPadingAngle = (absDeltaAngle >= 360 ? len : len - 1) * paddingAngle
  const realTotalAngle = absDeltaAngle - len * minAngle - totalPadingAngle
  const innerRadius = chartRadius * 0.35
  const minRadius = chartRadius * 0.6
  const maxRadius = chartRadius * 0.9

  let sectorData = pieData.map((entry, i) => {
    const r_val = getValueByDataKey(entry, realDataKey, 0)
    const val = isNumber(r_val) ? r_val : 0
    const name = getValueByDataKey(entry, nameKey, i)
    const percent = val / sum
    const sizePercent = val / max
    const outerRadius = minRadius + (maxRadius - minRadius) * sizePercent
    let tempStartAngle

    if (i) {
      tempStartAngle = prev.endAngle + mathSign(deltaAngle) * paddingAngle
    } else {
      tempStartAngle = startAngle
    }

    const tempEndAngle =
      tempStartAngle +
      mathSign(deltaAngle) * (minAngle + percent * realTotalAngle)
    const midAngle = (tempStartAngle + tempEndAngle) / 2
    const middleRadius = (innerRadius + outerRadius) / 2

    prev = {
      percent,
      sizePercent,
      cornerRadius,
      name,
      midAngle,
      middleRadius,
      ...entry,
      cx: chartRadius,
      cy: chartRadius,
      innerRadius,
      outerRadius,
      stroke: entry.stroke,
      fill: entry.color,
      labelColor: entry.labelColor || '#000000',
      strokeWidth: parseInt(1 + 2 * sizePercent),
      value: getValueByDataKey(entry, realDataKey),
      startAngle: tempStartAngle,
      endAngle: tempEndAngle,
      payload: entry,
      paddingAngle: mathSign(deltaAngle) * paddingAngle
    }
    return prev
  })

  const renderLabelContent = (props) => {
    let {
      percent,
      sizePercent,
      cx,
      cy,
      midAngle,
      middleRadius,
      name,
      labelColor
    } = props
    const RADIAN = Math.PI / 180
    let r = middleRadius
    let x = cx + r * Math.cos(-RADIAN * midAngle)
    let y = cy + r * Math.sin(-RADIAN * midAngle)

    return (
      <g
        transform={`translate(${x}, ${y})`}
        textAnchor="middle"
        style={{
          color: `${labelColor}`,
          fill: `${labelColor}`,
          fontSize: `${12 + sizePercent * 8}px`,
          pointerEvents: 'none'
        }}
      >
        <text x={0} y={0} className="percent">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text x={0} y={20} className="name">
          {`${name}`}
        </text>
      </g>
    )
  }

  return (
    <Surface width={chartRadius * 2} height={chartRadius * 2}>
      <Layer className="my-pie">
        <Layer className="my-pie-sectors">
          {sectorData.map((sector, index) => (
            <Layer key={index}>
              <Sector
                className="my-pie-sector"
                {...sector}
                opacity={activeIndex === -1 || index === activeIndex ? 1 : 0.2}
                onMouseEnter={() => {
                  //console.log('mouseEnter ', index)
                  handleActiveIndexChange(index)
                }}
                onMouseLeave={() => {
                  //console.log('mouseLeave ', index)
                  handleActiveIndexChange(-1)
                }}
              />
            </Layer>
          ))}
        </Layer>
        <Layer className="my-pie-labels">
          {sectorData.map((sector, index) => (
            <Fragment key={index}>
              {renderLabelContent({ ...sector }, index)}
            </Fragment>
          ))}
        </Layer>
      </Layer>
    </Surface>
  )
}

const PieChartContainer = (_) => {
  const [activeIndex, setCurrentIndex] = useState(-1)
  const [techActiveIndex, setTechActiveIndex] = useState(0)

  return (
    <div className="pie-chart-container">
      <div className="chart">
        <PieChart
          pieData={chartData}
          activeIndex={activeIndex}
          handleActiveIndexChange={(index) => {
            setCurrentIndex(index)
            if (index !== -1 && index !== techActiveIndex) {
              setTechActiveIndex(index)
            }
          }}
        />
      </div>
      <div className="legends">
        <ul>
          {techActiveIndex !== -1 &&
            chartData[techActiveIndex].tech.map((item, index) => (
              <li key={index} style={{ listStyle: 'inside', fontSize: 20 }}>
                {item}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default PieChartContainer
