import { Layout } from 'antd'
import { GLOBAL } from 'constants/app'
import React, { useState } from 'react'

const Footer = () => {
  return (
    <footer className="home">
      <nav className="flexbox">
        <form action="#" className="subscribe subscribe_mobile" type="post">
          <span className="input-text">
            <input
              id="subscribe_mobile_email"
              name="email"
              placeholder="Don't miss our updates"
              type="text"
            />
            <button name="subscribe_mobile_email_button" type="submit">
              <picture>
                <source
                  srcSet={require('assets/img/webp/icon_mail-02.webp').default}
                  type="image/webp"
                />
                <img
                  alt="letter"
                  src={require('assets/img/icon_mail-02.png').default}
                />
              </picture>
            </button>
          </span>
          <div className="error"></div>
        </form>
        <span className="footer-menu">
          <section>
            <p>Trusted by</p>
            <hr />
            <span>
              <a href="/dedicated-teams-startups/">Startups</a>
              <a href="/enterprise-solutions/">
                Mature
                <br />
                businesses
              </a>
            </span>
          </section>
          <section>
            <p>Development</p>
            <hr />
            <span>
              <a href="/services/">Services</a>
              <a href="/skills/">Skills</a>
              <a href="/solutions/">Solutions</a>
            </span>
          </section>
          <section>
            <p>Company</p>
            <hr />
            <span>
              <a href="/blog/">Blog</a>
              <a href="/careers/">Careers</a>
              <a href="/company/">About us</a>
              <a href="/our-partnerships/">Partnerships</a>
            </span>
          </section>
          <section className="social">
            <span className="contacts">
              <span className="phone">
                <a className="phone-link" href="tel:"></a>
              </span>
              <span className="address">
                Xu Yong Zhe: Investment Park-1, Dubai, UAE
                <br />
              </span>
              <span className="email-block">
                <a className="email" href="mailto:contact@hopanatech.com">
                  contact@hopanatech.com
                </a>
              </span>
            </span>
          </section>
        </span>
        <section className="social">
          <form action="#" className="subscribe" type="post">
            <span className="input-text">
              <input
                id="subscribe_email"
                name="email"
                placeholder="Don’t miss our updates"
                type="text"
              />
              <button name="subscribe_email_button" type="submit">
                <picture>
                  <img
                    alt="letter"
                    src={require('assets/svg/mail-icon.svg').default}
                  />
                </picture>
              </button>
            </span>
            <div
              className="error"
              data-gtm-vis-polling-id-8275798_279="155"
            ></div>
          </form>
          <span className="contacts">
            <span className="offices">
              <span>
                <span className="phone">
                  <a className="phone-link" href="tel:"></a>
                </span>
                <span className="address">
                  US:
                  <br/> 
                  Wang Kejia, tonywkj@hopanatech.com <br/>
                  65 Idlewild Rd, Edison, New Jersey, US, 08817<br/>
                  China:
                  <br/> 
                  Tong Yuze, tyz@hopanatech.com <br/>
                  北京市朝阳区王四营乡人民日报印刷厂综合业务楼7层799室<br/>
                  UAE:
                  <br/> 
                  Xu Yong Zhe, luke@hopanatech.com<br/>
                  Address: Flat-203, W Sub Metor, Investment Park-1, Dubai
                  <br />
                </span>
              </span>
              <span>
                <span
                  className="clutch-widget"
                  data-clutchcompany-id="80203"
                  data-height="50"
                  data-url="https://widget.clutch.co"
                  data-widget-type="1"
                ></span>
              </span>
            </span>
            <br />
          </span>
        </section>
      </nav>
      <div className="copyright">
        <a
          className="new_design_3_0_facebook"
          href="https://www.facebook.com/hopanatech"
          rel="noopener"
          target="_blank"
        ></a>
        <a
          className="new_design_3_0_in"
          href="https://www.linkedin.com/company/hopana-tech/"
          rel="noopener"
          target="_blank"
        ></a>
        <a
          className="new_design_3_0_twitter"
          href="https://twitter.com/hopana"
          rel="noopener"
          target="_blank"
        ></a>
        <a
          className="new_design_3_0_dribble"
          href="https://dribbble.com/hopana"
          rel="noopener"
          target="_blank"
        ></a>{' '}
        <span>
          © 2011 - 2021. {GLOBAL.COMPANY_NAME_LONG} All Rights Reserved. |{' '}
          <a className="copyright privacy" href="/privacy-policy-hopana/">
            Privacy&nbsp;policy
          </a>
        </span>
      </div>
      <script
        src="https://widget.clutch.co/static/js/widget.js"
        type="text/javascript"
      ></script>
    </footer>
  )
}

export default Footer
