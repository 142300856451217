import {
  HeaderBlock,
  RightBlock,
  LeftBlock,
  ContainerStack,
  Block
} from './element'
import { FeatureWork } from 'components/common'
import { gallery_data } from './fakedata'
import { Contact } from 'components/common'

const GalleryDetail = ({ _ }) => {
  let id = '694'
  let data =
    (gallery_data.length > 0) &
      (gallery_data.filter((item) => item.dataid === id).length > 0) &&
    gallery_data.filter((item) => item.dataid === id)[0]
  console.log('this is data', data)
  return (
    <div className="project page">
      <div className="blue_header" style={{ backgroundColor: '#2557f5' }}>
        <span>
          {data.projectname} / {`${data.tags[0]} ${data.tags[1]}`}
        </span>
        <img alt="bevi-02" src={data.detail.imgTopUrl} />
      </div>
      <div className="content">
        <HeaderBlock
          projectname={data.projectname}
          {...data.detail.headerBlock}
        />
        {data.detail.blocks.length > 0 &&
          data.detail.blocks.map((item) => <Block {...item} />)}
        <ContainerStack stacks={data.detail.stacks} />
      </div>
      <FeatureWork />
      <Contact />
    </div>
  )
}

export default GalleryDetail
