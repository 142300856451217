import React from 'react'
import DataItemsWrapper from 'components/common/data-items'

const ContainerNode = () => {
  return (
    <span className="container-1200 node">
      <span className="data grey">
        <h2 className="line">
          A Perfect mash-up
          <span>of code and soft skills</span>
        </h2>
        <DataItemsWrapper />
      </span>
    </span>
  )
}

export default ContainerNode
