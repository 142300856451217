const Item = ({ blog }) => {
  const { id, imgUrl, title, content, date, view, dataid } = blog

  return (
    <div className="blog-post" data-id={dataid}>
      <div className="image">
        <a href={`/blog/${id}`}>
          <img alt="virtual_events_1" height="" src={imgUrl} width="" />
        </a>
      </div>
      <div className="related-post-header">
        <a href={`/blog/${id}`}>{title}</a>
      </div>
      <div className="content">{content}</div>
      <div className="info">
        <div className="wrapper">
          {date}
          <span className="viewed">
            <img src={require('assets/img/eye-light-grey_.png').default} />
            {view}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Item
