import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { PUBLIC_PATHS, ROOT_PATH } from 'constants/paths'
import { useWindowDimensions } from 'utils/hook'
import { Collapse } from 'react-collapse'
import { WINDOWWIDTH } from 'constants/app'

const menu_items = [
  {
    id: 'services',
    text: 'Services',
    groups: [
      {
        id: 'web',
        text: 'Web',
        slug: 'web-development',
        pages: [
          {
            id: 'frontend-development',
            text: 'Frontend development'
          },
          {
            id: 'bankend-development',
            text: 'Backend development'
          }
        ]
      },
      {
        id: 'mobile',
        text: 'Mobile',
        slug: 'mobile-development',
        pages: [
          {
            id: 'ios-development',
            text: 'iOS'
          },
          {
            id: 'android-development',
            text: 'Android'
          },
          {
            id: 'cross-platform-development',
            text: 'Cross-platform'
          }
        ]
      },
      {
        id: 'testing',
        text: 'QA and testing',
        slug: 'software-testing',
        pages: [
          {
            id: 'test-automation',
            text: 'Test automation'
          },
          {
            id: 'mobile-testing',
            text: 'Mobile testing'
          }
        ]
      },
      {
        id: 'cloud-development',
        text: 'Cloud development',
        pages: [
          {
            id: 'saas-development',
            text: 'SaaS'
          },
          {
            id: 'devops-development',
            text: 'DevOps'
          },
          {
            id: 'sharepoint-development',
            text: 'SharePoint/Office 365'
          },
          {
            id: 'appexchange-development-services',
            text: 'Salesforce for ISVs',
            slug: 'appexchange-development-services'
          }
        ]
      },
      {
        id: 'ui-ux-design',
        text: 'UI/UX design',
        pages: []
      }
    ]
  },
  {
    id: 'skills',
    text: 'Skills',
    groups: [
      {
        id: 'open-source',
        text: 'Open source',
        pages: [
          {
            id: 'python',
            text: 'Python',
            slug: 'python-development-services'
          },
          {
            id: 'php',
            text: 'PHP',
            slug: 'php-development-services'
          },
          {
            id: 'ror',
            text: 'Ruby on Rails',
            slug: 'ror-development-services'
          },
          {
            id: 'golang',
            text: 'Golang',
            slug: 'golang-development-services'
          }
        ]
      },
      {
        id: 'time-tested',
        text: 'Time-tested',
        pages: [
          {
            id: 'dot-net',
            text: '.NET',
            slug: 'dot-net-development-services'
          },
          {
            id: 'java',
            text: 'Java',
            slug: 'java-development-services'
          }
        ]
      },
      {
        id: 'best-of-js',
        text: 'The best of JS',
        pages: [
          {
            id: 'react',
            text: 'React',
            slug: 'react-development-services'
          },
          {
            id: 'angular',
            text: 'Angular',
            slug: 'angular-development-services'
          },
          {
            id: 'node',
            text: 'Node',
            slug: 'node-development-services'
          },
          {
            id: 'vue',
            text: 'Vue',
            slug: 'vue-js-development-services'
          }
        ]
      },
      {
        id: 'business-allies',
        text: 'Business allies',
        pages: [
          {
            id: 'salesforce',
            text: 'Salesforce',
            slug: 'salesforce-development-services'
          },
          {
            id: 'salesforce-technical-audit',
            text: 'SF technical audit'
          },
          {
            id: 'salesforce-marketing',
            text: 'SF for marketing'
          }
        ]
      }
    ]
  },
  {
    id: 'solutions',
    text: 'Solutions',
    groups: [
      {
        id: 'industries',
        text: 'Industries',
        slug: 'industries',
        pages: [
          {
            id: 'fintech',
            text: 'Fintech'
          },
          {
            id: 'healthtech',
            text: 'HealthTech'
          },
          {
            id: 'ecommerce',
            text: 'Ecommerce'
          },
          {
            id: 'real-estate',
            text: 'Real Estate'
          },
          {
            id: 'adtech',
            text: 'Marketing and AdTech'
          }
        ]
      },
      {
        id: 'emerging-technologies',
        text: 'Emerging technologies',
        pages: [
          {
            id: 'big-data',
            text: 'Big data'
          },
          {
            id: 'ai',
            text: 'Artificial intelligence'
          },
          {
            id: 'ar-vr',
            text: 'AR/VR'
          },
          {
            id: 'cybersecurity',
            text: 'Cybersecurity'
          },
          {
            id: 'iot',
            text: 'Internet of things'
          },
          {
            id: 'business-intelligence',
            text: 'Business intelligence'
          },
          {
            id: 'blockchain',
            text: 'Blockchain'
          }
        ]
      }
    ]
  },
  {
    id: 'our-works',
    text: 'Case studies',
    groups: [
      {
        id: 'our-works',
        text: 'Go to portfolio',
        pages: []
      },
      {
        id: 'techology',
        text: 'Technology',
        pages: [
          {
            id: 'angular',
            text: 'Angular',
            slug: '?tags=angular'
          },
          {
            id: 'react',
            text: 'React',
            slug: '?tags=react'
          },
          {
            id: 'sf',
            text: 'Salesforce',
            slug: '?tags=sf'
          },
          {
            id: 'node',
            text: 'Node',
            slug: '?tags=node'
          },
          {
            id: 'mobile',
            text: 'Mobile',
            slug: '?tags=mobile'
          },
          {
            id: 'php',
            text: 'PHP',
            slug: '?tags=php'
          },
          {
            id: 'ror',
            text: 'Ruby on Rails',
            slug: '?tags=ror'
          },
          {
            id: 'dotnet',
            text: '.NET',
            slug: '?tags=dotnet'
          },
          {
            id: 'react-native',
            text: 'React Native',
            slug: '?tags=react-native'
          },
          {
            id: 'java',
            text: 'Java',
            slug: '?tags=java'
          },
          {
            id: 'python',
            text: 'Python',
            slug: '?tags=python'
          },
          {
            id: 'qa',
            text: 'QA and testing',
            slug: '?tags=qa'
          }
        ]
      }
    ]
  }
]

const NavBar = () => {
  const { pathname } = useLocation()

  const [active, setActive] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuItemIdx, setMenuItemIdx] = useState(0)
  const menuItemEls = useRef([])

  const { winheight, winwidth } = useWindowDimensions()

  const regexp = new RegExp(
    `${PUBLIC_PATHS.PORTFOLIO}|${PUBLIC_PATHS.CONTACT_US}|${PUBLIC_PATHS.BLOG}?`,
    'gi'
  )
  const isDark = !!pathname.match(regexp)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setActive(window.scrollY > 0)
  }

  const toggleMenu = () => {
    if (menuOpen) {
      //close first
      setMenuOpen(false)
    } else {
      if (winwidth < WINDOWWIDTH.mobile) {
      } else {
        setMenuOpen(!menuOpen)
      }
    }
  }

  return (
    <>
      <header
        className={`visible ${
          isDark
            ? 'desktop-dark mobile-white'
            : 'desktop-default mobile-default'
        } ${active ? 'active' : ''} ${menuOpen ? 'open' : ''}`}
      >
        <div className="header-wrapper">
          <span style={{ color: 'white' }}>
            this website is under development.
          </span>
          <div className="logo">
            <Link to={ROOT_PATH}>
              <img
                src={require('assets/img/logo-hopana-white.png').default}
                width="150"
                alt="logo"
              />
            </Link>
          </div>
          <div className="navigation-container">
            <nav className="mobile-hidden">
              <section>
                <Link className="top-level" to={PUBLIC_PATHS.HOW_WE_WORK}>
                  How we work
                </Link>
              </section>
              <section>
                <Link className="top-level" to={PUBLIC_PATHS.PORTFOLIO}>
                  Portfolio
                </Link>
              </section>
              <section>
                <Link className="top-level" to={PUBLIC_PATHS.BLOG}>
                  Blog
                </Link>
              </section>
              <section>
                <Link className="top-level" to={PUBLIC_PATHS.ABOUT_US}>
                  About Us
                </Link>
              </section>
            </nav>
            <div className="contact">
              <Link
                className="button-filled mobile-hidden"
                to={PUBLIC_PATHS.CONTACT_US}
              >
                CONTACT US
              </Link>
              <div
                className={`hamburger_close_menu ${menuOpen ? 'open' : ''}`}
                onClick={() => toggleMenu()}
              >
                <div className="hamburger_menu">
                  <div></div>
                  <div></div>
                </div>
                <div className="close_menu">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="progress-container">
          <div className="progress-bar"></div>
        </div>
      </header>
      <div className="blog-additional-header">
        <Link to="/blog/">BLOG</Link>
      </div>
      <div id="main-menu" className={menuOpen ? 'open' : ''}>
        <div className="container-1200">
          <span className="left-static-text hide" style={{ top: 321 }}>
            <span>since 2002</span> <span className="line">————</span>{' '}
            <span>All-in-one software teams</span>{' '}
            <span className="line">————</span> <span>1700+ Devs</span>
          </span>
          <div className="menu-wrapper">
            <div className="root_menu">
              {menu_items.map((item, index) => (
                <div
                  className={index === menuItemIdx ? 'active' : ''}
                  data-to={'/' + item.id + '/'}
                  data-menu={'menu-block-' + item.id}
                  key={item.id}
                  onMouseOver={() => {
                    setMenuItemIdx(index)
                  }}
                >
                  {item.text}
                </div>
              ))}
            </div>
            <div className="children">
              {menu_items.map((menuitem, index) => (
                <div
                  className={index === menuItemIdx ? 'active' : ''}
                  id={'menu-block-' + menuitem.id}
                  key={menuitem.id}
                >
                  {menuitem.groups.map((group, groupidx) => {
                    const gslug = group.slug || group.id
                    return (
                      <div className="child-block" key={group.id}>
                        <Link
                          className="group-name"
                          to={'/' + menuitem.id + '/' + gslug + '/'}
                        >
                          {group.text}:
                        </Link>
                        <div className="pages">
                          {group.pages.map((page, pageidx) => {
                            const pslug = page.slug || page.id
                            return (
                              <Link
                                to={'/' + menuitem.id + '/' + pslug + '/'}
                                key={page.id}
                              >
                                {page.text}
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="drawer"
        style={
          menuOpen
            ? { height: '100%', opacity: 1 }
            : { height: '0', opacity: 0 }
        }
      >
        <div className="menu-items">
          <div className="content">
            {menu_items.map((menuitem, index) => (
              <section
                className={`has-child ${menuItemIdx === index ? 'open' : ''}`}
                key={menuitem.id}
              >
                <Link
                  className="top-level"
                  to={'/' + menuitem.id + '/'}
                  ref={(element) => menuItemEls.current.push(element)}
                  onClick={(e) => {
                    //TODO: Smooth animation when a menu item is clicked to open.
                    // For now, instant scrolling the menu item makes the overall collapse/open
                    // animation jumpy.

                    e.preventDefault()
                    menuItemEls.current[index].scrollIntoView()
                    setMenuItemIdx(menuItemIdx === index ? -1 : index)
                  }}
                >
                  {menuitem.text}
                  <span></span>
                </Link>
                <Collapse isOpened={menuItemIdx === index}>
                  <div className="submenu">
                    <div className="content">
                      {menuitem.groups.map((group, groupidx) => {
                        const gslug = group.slug || group.id
                        return (
                          <section className="has-child" key={group.id}>
                            <Link
                              className="header"
                              to={'/' + menuitem.id + '/' + gslug + '/'}
                            >
                              {group.text}
                            </Link>
                            <div className="submenu">
                              <div className="content">
                                {group.pages.map((page, pageidx) => {
                                  const pslug = page.slug || page.id
                                  return (
                                    <div className="row" key={page.id}>
                                      <Link
                                        to={
                                          '/' + menuitem.id + '/' + pslug + '/'
                                        }
                                      >
                                        {page.text}
                                      </Link>
                                    </div>
                                  )
                                })}
                                <div className="row no-visible">&nbsp;</div>
                              </div>
                            </div>
                          </section>
                        )
                      })}
                    </div>
                  </div>
                </Collapse>
              </section>
            ))}
            <section>
              <Link className="top-level" to="/company/team-as-a-service/">
                How we work
              </Link>
            </section>
            <section>
              <Link className="top-level" to="/our-works/">
                Portfolio
              </Link>
            </section>
            <section>
              <Link className="top-level" to="/blog/">
                Blog
              </Link>
            </section>
            <section>
              <Link className="top-level" to="/company/">
                About Us
              </Link>
            </section>
            <section className="contact-as-menu-item">
              <Link
                className="contact contact-us"
                to="/company/contacts/#address"
              >
                <span>Contact Us</span>
              </Link>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavBar
