import { useQueryParam } from 'utils/hook'

import GalleryItem from './gallery-item'

const gallery_data = [
  {
    dataid: '694',
    dataorder: '1',
    imgUrl: require('assets/images/portfolio/classpass_case_hr.png').default,
    projectname: 'ClassPass',
    tags: ['android', 'java'],
    contents: ['Wellness', 'Fitness']
  },
  {
    dataid: '757',
    dataorder: '2',
    imgUrl: require('assets/images/portfolio/freshly_case.png').default,
    projectname: 'Freshly',
    tags: ['react', 'dnet'],
    contents: ['FoodTech', 'Ecomerce']
  },
  {
    dataid: '698',
    dataorder: '3',
    imgUrl: require('assets/images/portfolio/forex_case.png').default,
    projectname: 'Forex.com',
    tags: ['android', 'java'],
    contents: ['Forex Trading', 'FinTech']
  },
  {
    dataid: '953',
    dataorder: '4',
    imgUrl: require('assets/images/portfolio/bevi_case.png').default,
    projectname: 'Bevi',
    tags: ['sf', 'android'],
    contents: ['Food and Beverage']
  },
  {
    dataid: '749',
    dataorder: '5',
    imgUrl: require('assets/images/portfolio/blackboard_case.png').default,
    projectname: 'Blackboard',
    tags: ['android', 'java'],
    contents: ['Mobile', 'Elearning']
  },
  {
    dataid: '991',
    dataorder: '6',
    imgUrl: require('assets/images/portfolio/rallyrd_case_hr.png').default,
    projectname: 'Rally Rd.',
    tags: ['rn', 'angular'],
    contents: ['Automative', 'FinTech']
  },
  {
    dataid: '998',
    dataorder: '7',
    imgUrl: require('assets/images/portfolio/rallyrd_case_hr.png').default,
    projectname: 'Rally Rd. New',
    tags: ['rn', 'angular'],
    contents: ['Automative', 'FinTech']
  }
]

const default_tags = [
  {
    id: 'angular',
    name: 'Angular',
    state: false
  },
  {
    id: 'react',
    name: 'React',
    state: false
  },
  {
    id: 'ror',
    name: 'Ruby on Rails',
    state: false
  },
  {
    id: 'python',
    name: 'Python',
    state: false
  },
  {
    id: 'php',
    name: 'PHP',
    state: false
  },
  {
    id: 'node',
    name: 'Node',
    state: false
  },
  {
    id: 'dnet',
    name: '.NET',
    state: false
  },
  {
    id: 'java',
    name: 'Java',
    state: false
  },
  {
    id: 'sf',
    name: 'SalesForce',
    state: false
  },
  {
    id: 'mobile',
    name: 'Mobile',
    state: false
  },
  {
    id: 'qa',
    name: 'QA and Testing',
    state: false
  },
  {
    id: 'rn',
    name: 'React Native',
    state: false
  }
]



const Gallery = (_) => {
  const [tagsQuery, setTagsQuery] = useQueryParam("tags", "")
  
  let tags = default_tags
  let tagsActive = tagsQuery.split(",")
  let activeTagsCount = 0
  tags.forEach(tag => {
    tag.state = tagsActive.includes(tag.id)
    if(tag.state) {
      activeTagsCount++
    }
  })

  let galleries = activeTagsCount === 0 ? gallery_data : gallery_data.filter((item) => {
    return item.tags.filter((tag) => tagsActive.indexOf(tag) !== -1).length > 0
  })

  function toggle_(index) {
    tags[index].state = !tags[index].state

    let activetags = tags.filter((tag) => tag.state)
    let tagids = activetags.map((tag) => tag.id)
    setTagsQuery(tagids.join(","))
  }

  return (
    <main>
      <div className="projects">
        <picture>
          <img
            alt="img"
            src={
              require('assets/images/portfolio/our_works_header.png').default
            }
          />
        </picture>
        <div className="our_works_header">
          <h1>Our works</h1>
          <h3>
            We don’t have to pick our best works as we give our best shot every
            single time. Here are just some of our stellar projects to help
            inspire your own.
          </h3>
        </div>
        <div className="flexbox center">
          <div className="tags">
            <ul className="projects-filter">
              {tags.map((tag, index) => {
                return (
                  <li
                    data-tag={`${tag.name}`}
                    className={`${tag.state ? 'active' : ''}`}
                    key={tag.id}
                  >
                    <div onClick={() => toggle_(index)}>{tag.name}</div>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="flexbox" id="projects" style={{ display: 'flex' }}>
            {galleries.map((gallery, idx) => (
              <GalleryItem {...gallery} key={'gallery' + idx} />
            ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default Gallery
