import React from 'react'
import PerkItemsWrapper from 'components/common/perk-items-wrapper'

const PerkWithTeam = () => {
  return (
    <span className="container-1200">
      <span className="data">
        <h2 className="thin-middle">
          Perks that
          <br />
          go with&nbsp;<span>your team</span>
        </h2>
        <p>
          We adhere to the zero time waste logic when it comes to your product
          development. We carefully follow a roadmap to deliver on time and in
          line with investors’ and stakeholders’ expectations. Other goodies
          include:
        </p>
        <PerkItemsWrapper />
      </span>
    </span>
  )
}

export default PerkWithTeam
