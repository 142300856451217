import React, { useEffect, useState, useRef, createRef } from 'react'

import Video1Mp4 from 'assets/videos/lamp_white.mp4'
import Video1Webm from 'assets/videos/lamp_white.webm'
import Video2Mp4 from 'assets/videos/finger_white.mp4'
import Video2Webm from 'assets/videos/finger_white.webm'
import Video3Mp4 from 'assets/videos/people_white.mp4'
import Video3Webm from 'assets/videos/people_white.webm'
import Video4Mp4 from 'assets/videos/rocket_white.mp4'
import Video4Webm from 'assets/videos/rocket_white.webm'

const items = [
  {
    head: 'Step 1',
    mediaURL: {
      mp4: Video1Mp4,
      webm: Video1Webm
    },
    content:
      'We start with an immersion into your vision, challenges, and goals. This helps us maximize the efficiency of an internal vetting process.'
  },
  {
    head: 'Step 2',
    mediaURL: {
      mp4: Video2Mp4,
      webm: Video2Webm
    },
    content:
      'We deploy X-ray vision on our roster and get back to you with the perfect candidates’ CVs in just 24 hours.'
  },
  {
    head: 'Step 3',
    mediaURL: {
      mp4: Video3Mp4,
      webm: Video3Webm
    },
    content:
      'You interview engineers to find the perfect match. As soon as you give a thumbs-up to selected candidates, we can move on.'
  },
  {
    head: 'Step 4',
    mediaURL: {
      mp4: Video4Mp4,
      webm: Video4Webm
    },
    content:
      'This is the “woohoo!” moment. Your much-coveted team is in full-on prep mode to start.'
  }
]

const RedUnderlineChooser = () => {
  const [currentItem, setCurrentItem] = useState(0)
  const selVideo = useRef(null)
  const videos = []

  for (let i = 0; i < items.length; i++) {
    videos.push(createRef())
  }

  useEffect(() => {
    videos[0].current.play()
    selVideo.current.play()
  }, [])

  const onItemClicked = (idx) => {
    setCurrentItem((prev) => {
      videos[prev].current.pause()
      videos[prev].current.currentTime = 0
      videos[idx].current.play()

      selVideo.current.load()

      return idx
    })
  }

  return (
    <>
      <video
        className="mobile_video"
        autoPlay
        loop
        muted={true}
        playsInline
        webkit-playsinline="true"
        ref={selVideo}
        key={items[currentItem].mediaURL}
      >
        <source src={items[currentItem].mediaURL.mp4} type="video/mp4" />
        <source src={items[currentItem].mediaURL.webm} type="video/webm" />
      </video>
      <span className="red-underline-chooser">
        <span className="items">
          {items.map(({ head, content, mediaURL }, idx) => (
            <span
              className={idx === currentItem ? 'in_action' : ''}
              onClick={() => onItemClicked(idx)}
              key={head}
            >
              <span className="head">{head}</span>
              <span className="text">{content}</span>
              <video
                loop
                muted={true}
                playsInline
                webkit-playsinline="true"
                ref={videos[idx]}
              >
                <source src={mediaURL.mp4} type="video/mp4" />
                <source src={mediaURL.webm} type="video/webm" />
              </video>
            </span>
          ))}
        </span>
        <span className="content">
          <span className="head">{items[currentItem].head}</span>
          <span className="text">{items[currentItem].content}</span>
        </span>
      </span>
    </>
  )
}

export default RedUnderlineChooser
